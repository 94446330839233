import { ApolloClient } from '@apollo/client';
import {
    CreateNewIntegrationRecordDocument,
    CreateNewIntegrationRecordMutation,
    CreateNewIntegrationRecordMutationVariables,
    GetIntegrationRecordByIdQuery,
    GetIntegrationRecordByIdQueryVariables,
    GetIntegrationRecordByIdDocument,
    UpdateExistingIntegrationRecordMutation,
    UpdateExistingIntegrationRecordMutationVariables,
    UpdateExistingIntegrationRecordDocument,
    DeleteIntegrationRecordMutation,
    DeleteIntegrationRecordMutationVariables,
    DeleteIntegrationRecordDocument,
    GetAllIntegrationRecordsQuery,
    GetAllIntegrationRecordsQueryVariables,
    GetAllIntegrationRecordsDocument,
} from '../graphql/graphql-schema';
import BaseSDKHandler from './base-handler';

class IntegrationRecordsHandler extends BaseSDKHandler<
    CreateNewIntegrationRecordMutation,
    CreateNewIntegrationRecordMutationVariables,
    GetIntegrationRecordByIdQuery,
    GetIntegrationRecordByIdQueryVariables,
    UpdateExistingIntegrationRecordMutation,
    UpdateExistingIntegrationRecordMutationVariables,
    DeleteIntegrationRecordMutation,
    DeleteIntegrationRecordMutationVariables,
    GetAllIntegrationRecordsQuery,
    GetAllIntegrationRecordsQueryVariables
> {
    constructor(client: ApolloClient<object>) {
        super(
            client,
            'integration-records',
            CreateNewIntegrationRecordDocument,
            GetIntegrationRecordByIdDocument,
            UpdateExistingIntegrationRecordDocument,
            DeleteIntegrationRecordDocument,
            GetAllIntegrationRecordsDocument
        );
    }
}

export function createIntegrationRecordsHandler(client: ApolloClient<object>) {
    return new IntegrationRecordsHandler(client);
}
