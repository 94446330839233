import { ApolloClient } from '@apollo/client';
import BaseSDKHandler from './base-handler';
import {
    CreateNewGeneratedDocumentDocument,
    CreateNewGeneratedDocumentMutation,
    CreateNewGeneratedDocumentMutationVariables,
    DeleteGeneratedDocumentDocument,
    DeleteGeneratedDocumentMutation,
    DeleteGeneratedDocumentMutationVariables,
    GetAllGeneratedDocumentsDocument,
    GetAllGeneratedDocumentsQuery,
    GetAllGeneratedDocumentsQueryVariables,
    GetGeneratedDocumentByIdDocument,
    GetGeneratedDocumentByIdQuery,
    GetGeneratedDocumentByIdQueryVariables,
    UpdateExistingGeneratedDocumentDocument,
    UpdateExistingGeneratedDocumentMutation,
    UpdateExistingGeneratedDocumentMutationVariables,
} from '../graphql/graphql-schema';

class GeneratedDocumentsHandler extends BaseSDKHandler<
    CreateNewGeneratedDocumentMutation,
    CreateNewGeneratedDocumentMutationVariables,
    GetGeneratedDocumentByIdQuery,
    GetGeneratedDocumentByIdQueryVariables,
    UpdateExistingGeneratedDocumentMutation,
    UpdateExistingGeneratedDocumentMutationVariables,
    DeleteGeneratedDocumentMutation,
    DeleteGeneratedDocumentMutationVariables,
    GetAllGeneratedDocumentsQuery,
    GetAllGeneratedDocumentsQueryVariables
> {
    constructor(client: ApolloClient<object>) {
        super(
            client,
            'generated-documents',
            CreateNewGeneratedDocumentDocument,
            GetGeneratedDocumentByIdDocument,
            UpdateExistingGeneratedDocumentDocument,
            DeleteGeneratedDocumentDocument,
            GetAllGeneratedDocumentsDocument
        );
    }
}

export function createGeneratedDocumentsHandler(client: ApolloClient<object>) {
    return new GeneratedDocumentsHandler(client);
}
