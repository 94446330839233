import * as React from 'react';
import {
    createOrganizationsHandler,
    createIntegrationRecordsHandler,
    createUsersHandler,
    createProductsHandler,
    createReadOnlyHandler,
    createCustomersHandler,
    createDocumentsHandler,
} from './handlers';
import { ApolloClient } from '@apollo/client';
import { ConnectEntities } from './graphql';
import { createGeneratedDocumentsHandler } from './handlers/generated-document';

export class SDK {
    private client: ApolloClient<object>;
    public customers: ReturnType<typeof createCustomersHandler>;
    public documents: ReturnType<typeof createDocumentsHandler>;
    public generatedDocuments: ReturnType<
        typeof createGeneratedDocumentsHandler
    >;
    public organizations: ReturnType<typeof createOrganizationsHandler>;
    public users: ReturnType<typeof createUsersHandler>;
    public integrationRecords: ReturnType<
        typeof createIntegrationRecordsHandler
    >;
    public products: ReturnType<typeof createProductsHandler>;
    public readOnly: ReturnType<typeof createReadOnlyHandler>;

    constructor(private apolloClient: ApolloClient<object>) {
        this.client = apolloClient;
        this.customers = createCustomersHandler(this.client);
        this.documents = createDocumentsHandler(this.client);
        this.generatedDocuments = createGeneratedDocumentsHandler(this.client);
        this.organizations = createOrganizationsHandler(this.client);
        this.users = createUsersHandler(this.client);
        this.integrationRecords = createIntegrationRecordsHandler(this.client);
        this.products = createProductsHandler(this.client);
        this.readOnly = createReadOnlyHandler(this.client);
    }
}

const SDKContext = React.createContext<SDK | null>(null);

export interface SDKProviderProps {
    apolloClient: ApolloClient<object>;
    children: React.ReactNode;
}
export const SDKProvider = ({ children, apolloClient }: SDKProviderProps) => {
    const sdk = new SDK(apolloClient);
    return <SDKContext.Provider value={sdk}>{children}</SDKContext.Provider>;
};

export const useSDK = () => {
    const sdk = React.useContext(SDKContext);
    if (!sdk) {
        throw new Error('useSDK must be used within a SDKProvider');
    }
    return sdk;
};

type ReadOnlyCheckResponse = {
    data: boolean | undefined;
    isLoading: boolean;
    isError: boolean;
    error: Error | null;
};

export const useIsReadOnly = (
    entity: ConnectEntities
): ReadOnlyCheckResponse => {
    const sdk = useSDK();
    if (!sdk) {
        throw new Error('useSDK must be used within a SDKProvider');
    }
    const { data, isLoading, isError, error } = sdk.readOnly.useCheck({});
    const response: boolean | undefined = data?.check.info[entity];
    return { data: response, isLoading, isError, error };
};

export * from './graphql';
