import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
    [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
    [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
    [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
    T extends { [key: string]: unknown },
    K extends keyof T
> = { [_ in K]?: never };
export type Incremental<T> =
    | T
    | {
          [P in keyof T]?: P extends ' $fragmentName' | '__typename'
              ? T[P]
              : never;
      };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: { input: string; output: string };
    String: { input: string; output: string };
    Boolean: { input: boolean; output: boolean };
    Int: { input: number; output: number };
    Float: { input: number; output: number };
    /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
    DateTime: { input: any; output: any };
    /** The `Upload` scalar type represents a file upload. */
    Upload: { input: any; output: any };
};

export type Address = {
    __typename?: 'Address';
    city?: Maybe<Scalars['String']['output']>;
    country?: Maybe<Scalars['String']['output']>;
    createdAt: Scalars['DateTime']['output'];
    id: Scalars['ID']['output'];
    postalCode?: Maybe<Scalars['String']['output']>;
    state?: Maybe<Scalars['String']['output']>;
    street?: Maybe<Scalars['String']['output']>;
    streetNumber?: Maybe<Scalars['String']['output']>;
    unit?: Maybe<Scalars['String']['output']>;
    updatedAt: Scalars['DateTime']['output'];
};

export type AddressDto = {
    city: Scalars['String']['input'];
    country: Scalars['String']['input'];
    postalCode: Scalars['String']['input'];
    state: Scalars['String']['input'];
    street: Scalars['String']['input'];
    streetNumber: Scalars['String']['input'];
    unit?: InputMaybe<Scalars['String']['input']>;
};

export type CaseInfo = {
    __typename?: 'CaseInfo';
    areaUnit: UnitOfMeasure;
    casePack: Scalars['String']['output'];
    id: Scalars['ID']['output'];
    lengthUnit: UnitOfMeasure;
    masterCaseCube: Scalars['Float']['output'];
    masterCaseHeightTopToBottom: Scalars['Float']['output'];
    masterCaseLengthLeftToRight: Scalars['Float']['output'];
    masterCaseNetWt: Scalars['Float']['output'];
    masterCaseWidthFrontToBack: Scalars['Float']['output'];
    weightUnit: UnitOfMeasure;
};

export type CaseInfoDto = {
    areaUnit: UnitOfMeasureDto;
    casePack: Scalars['String']['input'];
    lengthUnit: UnitOfMeasureDto;
    masterCaseHeightTopToBottom: Scalars['Float']['input'];
    masterCaseLengthLeftToRight: Scalars['Float']['input'];
    masterCaseNetWt: Scalars['Float']['input'];
    masterCaseWidthFrontToBack: Scalars['Float']['input'];
    weightUnit: UnitOfMeasureDto;
};

/** The entities that are managed by this api. */
export enum ConnectEntities {
    IntegrationRecords = 'integrationRecords',
    Organizations = 'organizations',
    Products = 'products',
    Users = 'users',
}

export type CreateCustomValueDto = {
    columnIndex: Scalars['Float']['input'];
    value: Scalars['String']['input'];
};

export type CreateCustomerDto = {
    categoryChannel: Scalars['String']['input'];
    companyName: Scalars['String']['input'];
    creditLimit: Scalars['Float']['input'];
    customerServiceRep: Scalars['String']['input'];
    ediCapable: Scalars['Boolean']['input'];
    email: Scalars['String']['input'];
    fax: Scalars['String']['input'];
    parentCompany: Scalars['String']['input'];
    phone: Scalars['String']['input'];
    priceLevel: Scalars['String']['input'];
    primaryCurrency: Scalars['String']['input'];
    remoteId: Scalars['String']['input'];
    shippingMethod: Scalars['String']['input'];
    subsidiary: Scalars['String']['input'];
    terms: Scalars['String']['input'];
};

export type CreateDocumentDto = {
    customer: Scalars['String']['input'];
    documentStatus?: InputMaybe<DocumentStatus>;
    name?: InputMaybe<Scalars['String']['input']>;
    organization: Scalars['String']['input'];
    sheets?: InputMaybe<Array<DocumentSheetDto>>;
    uploadedBy?: InputMaybe<Scalars['String']['input']>;
};

export type CreateGeneratedDocumentDto = {
    customValues: Array<CreateCustomValueDto>;
    customer: Scalars['String']['input'];
    document: Scalars['String']['input'];
    organization: Scalars['String']['input'];
    product: Scalars['String']['input'];
};

export type CreateIntegrationRecordDto = {
    details: Array<NetsuiteDetailsDto>;
    integrationType: Scalars['String']['input'];
    mappings?: InputMaybe<Array<MappingDto>>;
    name: Scalars['String']['input'];
    organizationId: Scalars['String']['input'];
};

export type CreateLogDto = {
    integrationId: Scalars['String']['input'];
    logDate: Scalars['DateTime']['input'];
    logDescription: Scalars['String']['input'];
    logType: Scalars['String']['input'];
};

export type CreateOauthDto = {
    accessToken: Scalars['String']['input'];
    expiresOn: Scalars['DateTime']['input'];
    integrationId: Scalars['String']['input'];
    refreshToken: Scalars['String']['input'];
};

export type CreateOrganizationDto = {
    /** Organization address */
    address?: InputMaybe<AddressDto>;
    /** Organization contact email */
    contactEmail: Scalars['String']['input'];
    /** Organization name */
    name: Scalars['String']['input'];
    /** Organization owner */
    owner: Scalars['String']['input'];
};

export type CreateProductDto = {
    areaUnit: UnitOfMeasureDto;
    caseInfo: CaseInfoDto;
    code: Scalars['String']['input'];
    cutoff: Scalars['Float']['input'];
    description: Scalars['String']['input'];
    displayInfo: DisplayInfoDto;
    former: Scalars['Float']['input'];
    grossWeightBase: Scalars['Float']['input'];
    gtin14: Scalars['String']['input'];
    holePunchTearNotch: HolePunchTearNotchType;
    holePunchTearNotchDetails: Scalars['String']['input'];
    items: ItemDto;
    lengthUnit: UnitOfMeasureDto;
    overlap: Scalars['Float']['input'];
    palletInfo: PalletInfoDto;
    prices: Array<ProductPriceDto>;
    primaryUnitType: PrimaryUnitType;
    remoteId?: InputMaybe<Scalars['String']['input']>;
    retailCount: Scalars['Float']['input'];
    seal: SealType;
    servingUnit: ServingUnit;
    shipperInfo: ShipperInfoDto;
    truckloadInfo: TruckloadInfoDto;
    upc12: Scalars['String']['input'];
    weightUnit: UnitOfMeasureDto;
};

export type CreateUserDto = {
    avatarUrl?: InputMaybe<Scalars['String']['input']>;
    email: Scalars['String']['input'];
    firstName: Scalars['String']['input'];
    jobTitle?: InputMaybe<Scalars['String']['input']>;
    lastName: Scalars['String']['input'];
    organization?: InputMaybe<Scalars['String']['input']>;
    phone?: InputMaybe<Scalars['String']['input']>;
};

export enum CurrencyType {
    Empty = 'EMPTY',
    Eur = 'EUR',
    Usd = 'USD',
}

/** A column in a sheet */
export type CustomValue = {
    __typename?: 'CustomValue';
    columnIndex: Scalars['Float']['output'];
    value: Scalars['String']['output'];
};

/** Customer */
export type Customer = {
    __typename?: 'Customer';
    categoryChannel: Scalars['String']['output'];
    companyName: Scalars['String']['output'];
    creditLimit: Scalars['Float']['output'];
    customerServiceRep: Scalars['String']['output'];
    ediCapable: Scalars['Boolean']['output'];
    email: Scalars['String']['output'];
    fax: Scalars['String']['output'];
    id: Scalars['ID']['output'];
    parentCompany: Scalars['String']['output'];
    phone: Scalars['String']['output'];
    priceLevel: Scalars['String']['output'];
    primaryCurrency: Scalars['String']['output'];
    remoteId?: Maybe<Scalars['String']['output']>;
    shippingMethod: Scalars['String']['output'];
    subsidiary: Scalars['String']['output'];
    terms: Scalars['String']['output'];
};

export type CustomerOrStringUnion = Customer;

export type DeleteItemResponse = {
    __typename?: 'DeleteItemResponse';
    success: Scalars['Boolean']['output'];
};

export type DisplayInfo = {
    __typename?: 'DisplayInfo';
    heightTopToBottom: Scalars['Float']['output'];
    id: Scalars['ID']['output'];
    lengthLeftToRight: Scalars['Float']['output'];
    lengthUnit: UnitOfMeasure;
    netWeight: Scalars['Float']['output'];
    weightUnit: UnitOfMeasure;
    widthFrontToBack: Scalars['Float']['output'];
};

export type DisplayInfoDto = {
    heightTopToBottom: Scalars['Float']['input'];
    lengthLeftToRight: Scalars['Float']['input'];
    lengthUnit: UnitOfMeasureDto;
    netWeight: Scalars['Float']['input'];
    weightUnit: UnitOfMeasureDto;
    widthFrontToBack: Scalars['Float']['input'];
};

/** A sheet in a document */
export type DocumentSheet = {
    __typename?: 'DocumentSheet';
    columns?: Maybe<Array<SheetColumn>>;
    documentStatus: DocumentStatus;
    documentType: DocumentType;
    headerRow: Scalars['Float']['output'];
    index: Scalars['Float']['output'];
    name: Scalars['String']['output'];
};

export type DocumentSheetDto = {
    columns?: InputMaybe<Array<SheetColumnDto>>;
    documentStatus?: InputMaybe<DocumentStatus>;
    documentType: DocumentType;
    headerRow: Scalars['Float']['input'];
    index: Scalars['Float']['input'];
    name: Scalars['String']['input'];
};

export enum DocumentStatus {
    Deleted = 'DELETED',
    Mapped = 'MAPPED',
    New = 'NEW',
    Processed = 'PROCESSED',
    Unmapped = 'UNMAPPED',
}

export enum DocumentType {
    Ignore = 'IGNORE',
    NewItem = 'NEW_ITEM',
    Other = 'OTHER',
}

/** Generated document */
export type GeneratedDocument = {
    __typename?: 'GeneratedDocument';
    customValues: Array<CustomValue>;
    customer: Customer;
    document: UploadedDocument;
    id: Scalars['ID']['output'];
    organization: Organization;
    product: Product;
};

export enum HolePunchTearNotchType {
    HolePunch = 'HolePunch',
    TearNotch = 'TearNotch',
}

export type IntegrationDetailsUnion =
    | NetsuiteIntegrationDetails
    | OtherIntegrationDetails;

/** integration log */
export type IntegrationLog = {
    __typename?: 'IntegrationLog';
    id: Scalars['ID']['output'];
    integrationId: Scalars['String']['output'];
    logDate: Scalars['DateTime']['output'];
    logDescription: Scalars['String']['output'];
    logType: Scalars['String']['output'];
};

/** integration */
export type IntegrationRecord = {
    __typename?: 'IntegrationRecord';
    details: IntegrationDetailsUnion;
    id: Scalars['ID']['output'];
    integrationType: Scalars['String']['output'];
    mappings?: Maybe<MappingGroup>;
    name: Scalars['String']['output'];
    organizationId: Scalars['String']['output'];
};

export type Item = {
    __typename?: 'Item';
    filmWebWidth: Scalars['Float']['output'];
    flavor: Scalars['String']['output'];
    guaranteedShelfLife: Scalars['Float']['output'];
    heightTopToBottom: Scalars['Float']['output'];
    id: Scalars['ID']['output'];
    lengthLeftToRight: Scalars['Float']['output'];
    lengthUnit: UnitOfMeasure;
    netWeight: Scalars['Float']['output'];
    sellableWeight: Scalars['Float']['output'];
    shelfLifeDays: Scalars['Float']['output'];
    weightUnit: UnitOfMeasure;
    widthFrontToBack: Scalars['Float']['output'];
};

export type ItemDto = {
    filmWebWidth: Scalars['Float']['input'];
    flavor: Scalars['String']['input'];
    guaranteedShelfLife: Scalars['Float']['input'];
    heightTopToBottom: Scalars['Float']['input'];
    lengthLeftToRight: Scalars['Float']['input'];
    lengthUnit: UnitOfMeasureDto;
    netWeight: Scalars['Float']['input'];
    sellableWeight: Scalars['Float']['input'];
    shelfLifeDays: Scalars['Float']['input'];
    weightUnit: UnitOfMeasureDto;
    widthFrontToBack: Scalars['Float']['input'];
};

/** mappings */
export type Mapping = {
    __typename?: 'Mapping';
    connectAIField: Scalars['String']['output'];
    id: Scalars['ID']['output'];
    mapping: MappingDetails;
};

/** mapping */
export type MappingDetails = {
    __typename?: 'MappingDetails';
    hasChildren: Scalars['Boolean']['output'];
    id: Scalars['ID']['output'];
    parent: Scalars['String']['output'];
    remoteField: Scalars['String']['output'];
    type: MappingType;
};

export type MappingDetailsDto = {
    hasChildren: Scalars['Boolean']['input'];
    parent: Scalars['String']['input'];
    remoteField: Scalars['String']['input'];
    type: MappingType;
};

export type MappingDto = {
    connectAIField: Scalars['String']['input'];
    mapping: MappingDetailsDto;
};

/** mapping group */
export type MappingGroup = {
    __typename?: 'MappingGroup';
    customers?: Maybe<Array<Mapping>>;
    id: Scalars['ID']['output'];
    products?: Maybe<Array<Mapping>>;
};

export enum MappingType {
    Area = 'AREA',
    AreaType = 'AREA_TYPE',
    AreaUnit = 'AREA_UNIT',
    Boolean = 'BOOLEAN',
    Currency = 'CURRENCY',
    CurrencyType = 'CURRENCY_TYPE',
    CustomerCategoryChannel = 'CUSTOMER_CATEGORY_CHANNEL',
    HolePunchTearNotch = 'HOLE_PUNCH_TEAR_NOTCH',
    Length = 'LENGTH',
    LengthType = 'LENGTH_TYPE',
    LengthUnit = 'LENGTH_UNIT',
    List = 'LIST',
    Number = 'NUMBER',
    Object = 'OBJECT',
    PriceType = 'PRICE_TYPE',
    Seal = 'SEAL',
    String = 'STRING',
    Weight = 'WEIGHT',
    WeightType = 'WEIGHT_TYPE',
    WeightUnit = 'WEIGHT_UNIT',
}

export type MappingValue = {
    __typename?: 'MappingValue';
    label: Scalars['String']['output'];
    value: Scalars['String']['output'];
};

export type Mutation = {
    __typename?: 'Mutation';
    deleteCustomer: DeleteItemResponse;
    deleteDocument: DeleteItemResponse;
    deleteGeneratedDocument: DeleteItemResponse;
    deleteIntegration: DeleteItemResponse;
    deleteIntegrationLog: DeleteItemResponse;
    deleteOauth: DeleteItemResponse;
    deleteOrganization: DeleteItemResponse;
    deleteProduct: DeleteItemResponse;
    deleteUser: DeleteItemResponse;
    newCustomer: Customer;
    newDocument: UploadedDocument;
    newGeneratedDocument: GeneratedDocument;
    newIntegration: IntegrationRecord;
    newIntegrationLog: IntegrationLog;
    newOauth: Oauth;
    newOrganization: Organization;
    newProduct: Product;
    newUser: User;
    setRole: User;
    updateCustomer: Customer;
    updateDocument: UploadedDocument;
    updateGeneratedDocument: GeneratedDocument;
    updateIntegration: IntegrationRecord;
    updateIntegrationLog: IntegrationLog;
    updateOauth: Oauth;
    updateOrganization: Organization;
    updateProduct: Product;
    updateUser: User;
};

export type MutationDeleteCustomerArgs = {
    id: Scalars['String']['input'];
};

export type MutationDeleteDocumentArgs = {
    id: Scalars['String']['input'];
};

export type MutationDeleteGeneratedDocumentArgs = {
    id: Scalars['String']['input'];
};

export type MutationDeleteIntegrationArgs = {
    id: Scalars['String']['input'];
};

export type MutationDeleteIntegrationLogArgs = {
    id: Scalars['String']['input'];
};

export type MutationDeleteOauthArgs = {
    id: Scalars['String']['input'];
};

export type MutationDeleteOrganizationArgs = {
    id: Scalars['String']['input'];
};

export type MutationDeleteProductArgs = {
    id: Scalars['String']['input'];
};

export type MutationDeleteUserArgs = {
    id: Scalars['String']['input'];
};

export type MutationNewCustomerArgs = {
    input: CreateCustomerDto;
};

export type MutationNewDocumentArgs = {
    file: Scalars['Upload']['input'];
    input: CreateDocumentDto;
};

export type MutationNewGeneratedDocumentArgs = {
    input: CreateGeneratedDocumentDto;
};

export type MutationNewIntegrationArgs = {
    input: CreateIntegrationRecordDto;
};

export type MutationNewIntegrationLogArgs = {
    input: CreateLogDto;
};

export type MutationNewOauthArgs = {
    input: CreateOauthDto;
};

export type MutationNewOrganizationArgs = {
    input: CreateOrganizationDto;
};

export type MutationNewProductArgs = {
    input: CreateProductDto;
};

export type MutationNewUserArgs = {
    input: CreateUserDto;
};

export type MutationSetRoleArgs = {
    SetRoleInput: SetRolesDto;
};

export type MutationUpdateCustomerArgs = {
    id: Scalars['String']['input'];
    input: UpdateCustomerDto;
};

export type MutationUpdateDocumentArgs = {
    id: Scalars['String']['input'];
    input: UpdateDocumentDto;
};

export type MutationUpdateGeneratedDocumentArgs = {
    id: Scalars['String']['input'];
    input: UpdateGeneratedDocumentDto;
};

export type MutationUpdateIntegrationArgs = {
    id: Scalars['String']['input'];
    input: UpdateIntegrationRecordDto;
};

export type MutationUpdateIntegrationLogArgs = {
    id: Scalars['String']['input'];
    input: UpdateLogDto;
};

export type MutationUpdateOauthArgs = {
    id: Scalars['String']['input'];
    input: UpdateOauthDto;
};

export type MutationUpdateOrganizationArgs = {
    id: Scalars['String']['input'];
    input: UpdateOrganizationDto;
};

export type MutationUpdateProductArgs = {
    id: Scalars['String']['input'];
    input: UpdateProductDto;
};

export type MutationUpdateUserArgs = {
    id: Scalars['String']['input'];
    input: UpdateUserDto;
};

export type NetsuiteDetailsDto = {
    accountId: Scalars['String']['input'];
    clientId: Scalars['String']['input'];
    clientSecret: Scalars['String']['input'];
    companyUrl: Scalars['String']['input'];
};

export type NetsuiteIntegrationDetails = {
    __typename?: 'NetsuiteIntegrationDetails';
    accountId: Scalars['String']['output'];
    clientId: Scalars['String']['output'];
    clientSecret: Scalars['String']['output'];
    companyUrl: Scalars['String']['output'];
};

/** An oauth credential set */
export type Oauth = {
    __typename?: 'Oauth';
    accessToken: Scalars['String']['output'];
    expiresOn: Scalars['DateTime']['output'];
    id: Scalars['ID']['output'];
    integrationId: Scalars['String']['output'];
    refreshToken: Scalars['String']['output'];
};

export type Organization = {
    __typename?: 'Organization';
    /** Organization address */
    address: Address;
    avatarUrl?: Maybe<Scalars['String']['output']>;
    /** Organization contact email */
    contactEmail: Scalars['String']['output'];
    /** Organization contact phone */
    contactPhone?: Maybe<Scalars['String']['output']>;
    /** Created by user */
    createdBy: User;
    /** Deleted at */
    deletedAt: Scalars['DateTime']['output'];
    /** Deleted by user */
    deletedBy: User;
    id: Scalars['ID']['output'];
    /** Organization name */
    name: Scalars['String']['output'];
    /** Organization owner */
    owner: Scalars['String']['output'];
    /** Updated by user */
    updatedBy: User;
};

export type OrganizationOrStringUnion = Organization;

export type OtherIntegrationDetails = {
    __typename?: 'OtherIntegrationDetails';
    endPointUri: Scalars['String']['output'];
    token: Scalars['String']['output'];
};

export type PalletInfo = {
    __typename?: 'PalletInfo';
    areaUnit: UnitOfMeasure;
    casesOrUnitsPer: Scalars['Float']['output'];
    cube: Scalars['Float']['output'];
    cubeCFWoPallet: Scalars['Float']['output'];
    grossWeight: Scalars['Float']['output'];
    grossWeightWoPallet: Scalars['Float']['output'];
    heightTopToBottom: Scalars['Float']['output'];
    heightTopToBottomWoPallet: Scalars['Float']['output'];
    hi: Scalars['Float']['output'];
    id: Scalars['ID']['output'];
    lengthLeftToRight: Scalars['Float']['output'];
    lengthUnit: UnitOfMeasure;
    netWeight: Scalars['Float']['output'];
    perContainer: Scalars['Float']['output'];
    perTruckload: Scalars['Float']['output'];
    ti: Scalars['Float']['output'];
    weightUnit: UnitOfMeasure;
    widthFrontToBack: Scalars['Float']['output'];
};

export type PalletInfoDto = {
    areaUnit: UnitOfMeasureDto;
    casesOrUnitsPer: Scalars['Float']['input'];
    grossWeight: Scalars['Float']['input'];
    grossWeightWoPallet: Scalars['Float']['input'];
    heightTopToBottom: Scalars['Float']['input'];
    heightTopToBottomWoPallet: Scalars['Float']['input'];
    hi: Scalars['Float']['input'];
    lengthLeftToRight: Scalars['Float']['input'];
    lengthUnit: UnitOfMeasureDto;
    netWeight: Scalars['Float']['input'];
    perContainer: Scalars['Float']['input'];
    perTruckload: Scalars['Float']['input'];
    ti: Scalars['Float']['input'];
    weightUnit: UnitOfMeasureDto;
    widthFrontToBack: Scalars['Float']['input'];
};

export enum PrimaryUnitType {
    Bag = 'Bag',
    Case = 'Case',
    Empty = 'Empty',
    Pallet = 'Pallet',
    Shipper = 'Shipper',
    Weight = 'Weight',
}

export type Product = {
    __typename?: 'Product';
    areaUnit: UnitOfMeasure;
    caseInfo: CaseInfo;
    code: Scalars['String']['output'];
    cutoff: Scalars['Float']['output'];
    description: Scalars['String']['output'];
    displayInfo: DisplayInfo;
    former: Scalars['Float']['output'];
    grossWeightBase: Scalars['Float']['output'];
    gtin14: Scalars['String']['output'];
    holePunchTearNotch: HolePunchTearNotchType;
    holePunchTearNotchDetails: Scalars['String']['output'];
    id: Scalars['ID']['output'];
    items: Item;
    lengthUnit: UnitOfMeasure;
    overlap: Scalars['Float']['output'];
    palletInfo: PalletInfo;
    prices: Array<ProductPrice>;
    primaryUnitType: PrimaryUnitType;
    remoteId?: Maybe<Scalars['String']['output']>;
    retailCount: Scalars['Float']['output'];
    seal: SealType;
    servingUnit: ServingUnit;
    shipperInfo: ShipperInfo;
    truckloadInfo: TruckloadInfo;
    upc12: Scalars['String']['output'];
    weightUnit: UnitOfMeasure;
};

export type ProductPrice = {
    __typename?: 'ProductPrice';
    currency: CurrencyType;
    id: Scalars['ID']['output'];
    price: Scalars['Float']['output'];
    priceLevel: Scalars['String']['output'];
    priceQuantity?: Maybe<Scalars['Float']['output']>;
};

export type ProductPriceDto = {
    currency: CurrencyType;
    price: Scalars['Float']['input'];
    priceLevel: Scalars['String']['input'];
    priceQuantity?: InputMaybe<Scalars['Float']['input']>;
    product: Scalars['String']['input'];
};

export type Query = {
    __typename?: 'Query';
    Organization: Organization;
    Organizations: Array<Organization>;
    Product: Product;
    Products: Array<Product>;
    User: User;
    Users: Array<User>;
    check: ReadOnlyMode;
    customer: Customer;
    customers: Array<Customer>;
    document: UploadedDocument;
    documents: Array<UploadedDocument>;
    generatedDocument: GeneratedDocument;
    generatedDocuments: Array<GeneratedDocument>;
    getAvailableEntities: ConnectEntities;
    integration: IntegrationRecord;
    integrationLog: IntegrationLog;
    integrationLogs: Array<IntegrationLog>;
    integrations: Array<IntegrationRecord>;
    mapValues: Array<MappingValue>;
    me: User;
    oauth: Oauth;
    oauthList: Array<Oauth>;
};

export type QueryOrganizationArgs = {
    id: Scalars['String']['input'];
};

export type QueryProductArgs = {
    id: Scalars['String']['input'];
};

export type QueryUserArgs = {
    id: Scalars['String']['input'];
};

export type QueryCustomerArgs = {
    id: Scalars['String']['input'];
};

export type QueryDocumentArgs = {
    id: Scalars['String']['input'];
};

export type QueryGeneratedDocumentArgs = {
    id: Scalars['String']['input'];
};

export type QueryIntegrationArgs = {
    id: Scalars['String']['input'];
};

export type QueryIntegrationLogArgs = {
    id: Scalars['String']['input'];
};

export type QueryOauthArgs = {
    id: Scalars['String']['input'];
};

export type ReadOnlyInfo = {
    __typename?: 'ReadOnlyInfo';
    integrationRecords: Scalars['Boolean']['output'];
    organizations: Scalars['Boolean']['output'];
    products: Scalars['Boolean']['output'];
    users: Scalars['Boolean']['output'];
};

export type ReadOnlyMode = {
    __typename?: 'ReadOnlyMode';
    info: ReadOnlyInfo;
};

export enum SealType {
    Fin = 'FIN',
    Lap = 'LAP',
}

export enum ServingUnit {
    CanadaOnly = 'CanadaOnly',
    Club = 'Club',
    Empty = 'Empty',
    EventSamples = 'EventSamples',
    FoodService = 'FoodService',
    PrivateLabel = 'PrivateLabel',
    Retail = 'Retail',
    Shippers = 'Shippers',
    ShoppablePallet = 'ShoppablePallet',
    SingleServe = 'SingleServe',
}

export type SetRolesDto = {
    id: Scalars['ID']['input'];
    roles: Array<UserRole>;
};

/** A column in a sheet */
export type SheetColumn = {
    __typename?: 'SheetColumn';
    index: Scalars['Float']['output'];
    mapping: SheetColumnMapping;
    name: Scalars['String']['output'];
};

export type SheetColumnDto = {
    index: Scalars['Float']['input'];
    mapping: SheetColumnMappingDto;
    name: Scalars['String']['input'];
};

/** A mapping for a column */
export type SheetColumnMapping = {
    __typename?: 'SheetColumnMapping';
    connectAIColumn: Scalars['String']['output'];
    extraVerification?: Maybe<Scalars['Boolean']['output']>;
};

export type SheetColumnMappingDto = {
    connectAIColumn: Scalars['String']['input'];
    extraVerification?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ShipperInfo = {
    __typename?: 'ShipperInfo';
    areaUnit: UnitOfMeasure;
    cube: Scalars['Float']['output'];
    heightTopToBottom: Scalars['Float']['output'];
    id: Scalars['ID']['output'];
    lengthLeftToRight: Scalars['Float']['output'];
    lengthUnit: UnitOfMeasure;
    netWeight: Scalars['Float']['output'];
    weightUnit: UnitOfMeasure;
    widthFrontToBack: Scalars['Float']['output'];
};

export type ShipperInfoDto = {
    areaUnit: UnitOfMeasureDto;
    cube: Scalars['Float']['input'];
    heightTopToBottom: Scalars['Float']['input'];
    lengthLeftToRight: Scalars['Float']['input'];
    lengthUnit: UnitOfMeasureDto;
    netWeight: Scalars['Float']['input'];
    weightUnit: UnitOfMeasureDto;
    widthFrontToBack: Scalars['Float']['input'];
};

export enum StackLevel {
    Double = 'Double',
    Single = 'Single',
    Triple = 'Triple',
}

export type TruckloadInfo = {
    __typename?: 'TruckloadInfo';
    grossWeighttPer: Scalars['Float']['output'];
    id: Scalars['ID']['output'];
    stackLevel: StackLevel;
    unitsPer: Scalars['Float']['output'];
    weightUnit: UnitOfMeasure;
};

export type TruckloadInfoDto = {
    grossWeighttPer: Scalars['Float']['input'];
    stackLevel: StackLevel;
    unitsPer: Scalars['Float']['input'];
    weightUnit: UnitOfMeasureDto;
};

export type UnitOfMeasure = {
    __typename?: 'UnitOfMeasure';
    id: Scalars['ID']['output'];
    type: UnitType;
    unit: Scalars['String']['output'];
};

export type UnitOfMeasureDto = {
    type: UnitType;
    unit: Scalars['String']['input'];
};

export enum UnitType {
    Length = 'Length',
    Mass = 'Mass',
    Volume = 'Volume',
}

export type UpdateCustomerDto = {
    categoryChannel?: InputMaybe<Scalars['String']['input']>;
    companyName?: InputMaybe<Scalars['String']['input']>;
    creditLimit?: InputMaybe<Scalars['Float']['input']>;
    customerServiceRep?: InputMaybe<Scalars['String']['input']>;
    ediCapable?: InputMaybe<Scalars['Boolean']['input']>;
    email?: InputMaybe<Scalars['String']['input']>;
    fax?: InputMaybe<Scalars['String']['input']>;
    parentCompany?: InputMaybe<Scalars['String']['input']>;
    phone?: InputMaybe<Scalars['String']['input']>;
    priceLevel?: InputMaybe<Scalars['String']['input']>;
    primaryCurrency?: InputMaybe<Scalars['String']['input']>;
    remoteId?: InputMaybe<Scalars['String']['input']>;
    shippingMethod?: InputMaybe<Scalars['String']['input']>;
    subsidiary?: InputMaybe<Scalars['String']['input']>;
    terms?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateDocumentDto = {
    customer?: InputMaybe<Scalars['String']['input']>;
    documentStatus?: InputMaybe<DocumentStatus>;
    name?: InputMaybe<Scalars['String']['input']>;
    organization?: InputMaybe<Scalars['String']['input']>;
    sheets?: InputMaybe<Array<DocumentSheetDto>>;
    uploadedBy?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateGeneratedDocumentDto = {
    customValues: Array<CreateCustomValueDto>;
};

export type UpdateIntegrationRecordDto = {
    details?: InputMaybe<Array<NetsuiteDetailsDto>>;
    integrationType?: InputMaybe<Scalars['String']['input']>;
    mappings?: InputMaybe<Array<MappingDto>>;
    name?: InputMaybe<Scalars['String']['input']>;
    organizationId?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateLogDto = {
    integrationId?: InputMaybe<Scalars['String']['input']>;
    logDate?: InputMaybe<Scalars['DateTime']['input']>;
    logDescription?: InputMaybe<Scalars['String']['input']>;
    logType?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateOauthDto = {
    accessToken?: InputMaybe<Scalars['String']['input']>;
    expiresOn?: InputMaybe<Scalars['DateTime']['input']>;
    integrationId?: InputMaybe<Scalars['String']['input']>;
    refreshToken?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateOrganizationDto = {
    /** Organization address */
    address?: InputMaybe<AddressDto>;
    /** Organization contact email */
    contactEmail?: InputMaybe<Scalars['String']['input']>;
    id: Scalars['ID']['input'];
    /** Organization name */
    name?: InputMaybe<Scalars['String']['input']>;
    /** Organization owner */
    owner?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateProductDto = {
    areaUnit?: InputMaybe<UnitOfMeasureDto>;
    caseInfo?: InputMaybe<CaseInfoDto>;
    code?: InputMaybe<Scalars['String']['input']>;
    cutoff?: InputMaybe<Scalars['Float']['input']>;
    description?: InputMaybe<Scalars['String']['input']>;
    displayInfo?: InputMaybe<DisplayInfoDto>;
    former?: InputMaybe<Scalars['Float']['input']>;
    grossWeightBase?: InputMaybe<Scalars['Float']['input']>;
    gtin14?: InputMaybe<Scalars['String']['input']>;
    holePunchTearNotch?: InputMaybe<HolePunchTearNotchType>;
    holePunchTearNotchDetails?: InputMaybe<Scalars['String']['input']>;
    items?: InputMaybe<ItemDto>;
    lengthUnit?: InputMaybe<UnitOfMeasureDto>;
    overlap?: InputMaybe<Scalars['Float']['input']>;
    palletInfo?: InputMaybe<PalletInfoDto>;
    prices?: InputMaybe<Array<ProductPriceDto>>;
    primaryUnitType?: InputMaybe<PrimaryUnitType>;
    remoteId?: InputMaybe<Scalars['String']['input']>;
    retailCount?: InputMaybe<Scalars['Float']['input']>;
    seal?: InputMaybe<SealType>;
    servingUnit?: InputMaybe<ServingUnit>;
    shipperInfo?: InputMaybe<ShipperInfoDto>;
    truckloadInfo?: InputMaybe<TruckloadInfoDto>;
    upc12?: InputMaybe<Scalars['String']['input']>;
    weightUnit?: InputMaybe<UnitOfMeasureDto>;
};

export type UpdateUserDto = {
    avatarUrl?: InputMaybe<Scalars['String']['input']>;
    email?: InputMaybe<Scalars['String']['input']>;
    firstName?: InputMaybe<Scalars['String']['input']>;
    id: Scalars['ID']['input'];
    jobTitle?: InputMaybe<Scalars['String']['input']>;
    lastName?: InputMaybe<Scalars['String']['input']>;
    organization?: InputMaybe<Scalars['String']['input']>;
    phone?: InputMaybe<Scalars['String']['input']>;
};

/** A document that is uploaded by a user */
export type UploadedDocument = {
    __typename?: 'UploadedDocument';
    customer: CustomerOrStringUnion;
    deletedOn?: Maybe<Scalars['DateTime']['output']>;
    documentStatus: DocumentStatus;
    downloadUrl?: Maybe<Scalars['String']['output']>;
    id: Scalars['ID']['output'];
    name: Scalars['String']['output'];
    organization: OrganizationOrStringUnion;
    sheets?: Maybe<Array<DocumentSheet>>;
    uploadedBy?: Maybe<Scalars['String']['output']>;
};

export type User = {
    __typename?: 'User';
    avatarUrl?: Maybe<Scalars['String']['output']>;
    /** Created by user */
    createdBy: User;
    /** Deleted at */
    deletedAt: Scalars['DateTime']['output'];
    email: Scalars['String']['output'];
    firstName: Scalars['String']['output'];
    id: Scalars['ID']['output'];
    idpId: Scalars['String']['output'];
    jobTitle?: Maybe<Scalars['String']['output']>;
    lastName: Scalars['String']['output'];
    organization?: Maybe<Scalars['String']['output']>;
    phone?: Maybe<Scalars['String']['output']>;
    roles: Array<UserRole>;
    /** Updated by user */
    updatedBy: User;
};

/** User roles */
export enum UserRole {
    Admin = 'Admin',
    GlobalAdmin = 'GlobalAdmin',
    OrgAdmin = 'OrgAdmin',
    User = 'User',
}

export type CreateNewCustomerMutationVariables = Exact<{
    input: CreateCustomerDto;
}>;

export type CreateNewCustomerMutation = {
    __typename?: 'Mutation';
    newCustomer: {
        __typename?: 'Customer';
        id: string;
        remoteId?: string | null;
        companyName: string;
        subsidiary: string;
        categoryChannel: string;
        customerServiceRep: string;
        terms: string;
        email: string;
        phone: string;
        fax: string;
        shippingMethod: string;
        priceLevel: string;
        creditLimit: number;
        primaryCurrency: string;
        ediCapable: boolean;
    };
};

export type CreateNewDocumentMutationVariables = Exact<{
    input: CreateDocumentDto;
    file: Scalars['Upload']['input'];
}>;

export type CreateNewDocumentMutation = {
    __typename?: 'Mutation';
    newDocument: {
        __typename?: 'UploadedDocument';
        id: string;
        downloadUrl?: string | null;
        name: string;
        deletedOn?: any | null;
        uploadedBy?: string | null;
        documentStatus: DocumentStatus;
        organization: { __typename?: 'Organization'; id: string; name: string };
        customer: { __typename?: 'Customer'; id: string; companyName: string };
        sheets?: Array<{
            __typename?: 'DocumentSheet';
            index: number;
            name: string;
            documentType: DocumentType;
            documentStatus: DocumentStatus;
            headerRow: number;
            columns?: Array<{
                __typename?: 'SheetColumn';
                index: number;
                name: string;
                mapping: {
                    __typename?: 'SheetColumnMapping';
                    connectAIColumn: string;
                    extraVerification?: boolean | null;
                };
            }> | null;
        }> | null;
    };
};

export type CreateNewGeneratedDocumentMutationVariables = Exact<{
    input: CreateGeneratedDocumentDto;
}>;

export type CreateNewGeneratedDocumentMutation = {
    __typename?: 'Mutation';
    newGeneratedDocument: {
        __typename?: 'GeneratedDocument';
        id: string;
        organization: { __typename?: 'Organization'; id: string };
        customer: { __typename?: 'Customer'; id: string };
        document: { __typename?: 'UploadedDocument'; id: string };
        product: {
            __typename?: 'Product';
            id: string;
            code: string;
            description: string;
            servingUnit: ServingUnit;
            primaryUnitType: PrimaryUnitType;
            retailCount: number;
            grossWeightBase: number;
            weightUnit: {
                __typename?: 'UnitOfMeasure';
                id: string;
                type: UnitType;
                unit: string;
            };
            lengthUnit: {
                __typename?: 'UnitOfMeasure';
                id: string;
                type: UnitType;
                unit: string;
            };
            areaUnit: {
                __typename?: 'UnitOfMeasure';
                id: string;
                type: UnitType;
                unit: string;
            };
            items: {
                __typename?: 'Item';
                id: string;
                flavor: string;
                guaranteedShelfLife: number;
                shelfLifeDays: number;
                lengthLeftToRight: number;
                widthFrontToBack: number;
                heightTopToBottom: number;
                netWeight: number;
                sellableWeight: number;
                filmWebWidth: number;
                weightUnit: {
                    __typename?: 'UnitOfMeasure';
                    id: string;
                    type: UnitType;
                    unit: string;
                };
                lengthUnit: {
                    __typename?: 'UnitOfMeasure';
                    id: string;
                    type: UnitType;
                    unit: string;
                };
            };
            caseInfo: {
                __typename?: 'CaseInfo';
                id: string;
                casePack: string;
                masterCaseCube: number;
                masterCaseLengthLeftToRight: number;
                masterCaseWidthFrontToBack: number;
                masterCaseHeightTopToBottom: number;
                masterCaseNetWt: number;
                weightUnit: {
                    __typename?: 'UnitOfMeasure';
                    id: string;
                    type: UnitType;
                    unit: string;
                };
                lengthUnit: {
                    __typename?: 'UnitOfMeasure';
                    id: string;
                    type: UnitType;
                    unit: string;
                };
                areaUnit: {
                    __typename?: 'UnitOfMeasure';
                    id: string;
                    type: UnitType;
                    unit: string;
                };
            };
            palletInfo: {
                __typename?: 'PalletInfo';
                id: string;
                perTruckload: number;
                perContainer: number;
                ti: number;
                hi: number;
                casesOrUnitsPer: number;
                grossWeightWoPallet: number;
                grossWeight: number;
                heightTopToBottomWoPallet: number;
                netWeight: number;
                heightTopToBottom: number;
                widthFrontToBack: number;
                lengthLeftToRight: number;
                cube: number;
                cubeCFWoPallet: number;
                weightUnit: {
                    __typename?: 'UnitOfMeasure';
                    id: string;
                    type: UnitType;
                    unit: string;
                };
                lengthUnit: {
                    __typename?: 'UnitOfMeasure';
                    id: string;
                    type: UnitType;
                    unit: string;
                };
                areaUnit: {
                    __typename?: 'UnitOfMeasure';
                    id: string;
                    type: UnitType;
                    unit: string;
                };
            };
            displayInfo: {
                __typename?: 'DisplayInfo';
                id: string;
                lengthLeftToRight: number;
                widthFrontToBack: number;
                heightTopToBottom: number;
                netWeight: number;
                weightUnit: {
                    __typename?: 'UnitOfMeasure';
                    id: string;
                    type: UnitType;
                    unit: string;
                };
                lengthUnit: {
                    __typename?: 'UnitOfMeasure';
                    id: string;
                    type: UnitType;
                    unit: string;
                };
            };
            shipperInfo: {
                __typename?: 'ShipperInfo';
                id: string;
                lengthLeftToRight: number;
                widthFrontToBack: number;
                heightTopToBottom: number;
                netWeight: number;
                cube: number;
                weightUnit: {
                    __typename?: 'UnitOfMeasure';
                    id: string;
                    type: UnitType;
                    unit: string;
                };
                lengthUnit: {
                    __typename?: 'UnitOfMeasure';
                    id: string;
                    type: UnitType;
                    unit: string;
                };
                areaUnit: {
                    __typename?: 'UnitOfMeasure';
                    id: string;
                    type: UnitType;
                    unit: string;
                };
            };
            truckloadInfo: {
                __typename?: 'TruckloadInfo';
                id: string;
                grossWeighttPer: number;
                unitsPer: number;
                stackLevel: StackLevel;
                weightUnit: {
                    __typename?: 'UnitOfMeasure';
                    id: string;
                    type: UnitType;
                    unit: string;
                };
            };
        };
        customValues: Array<{
            __typename?: 'CustomValue';
            columnIndex: number;
            value: string;
        }>;
    };
};

export type CreateNewIntegrationRecordMutationVariables = Exact<{
    input: CreateIntegrationRecordDto;
}>;

export type CreateNewIntegrationRecordMutation = {
    __typename?: 'Mutation';
    newIntegration: {
        __typename?: 'IntegrationRecord';
        id: string;
        name: string;
        integrationType: string;
        organizationId: string;
        details:
            | {
                  __typename?: 'NetsuiteIntegrationDetails';
                  accountId: string;
                  clientId: string;
                  clientSecret: string;
                  companyUrl: string;
              }
            | {
                  __typename?: 'OtherIntegrationDetails';
                  endPointUri: string;
                  token: string;
              };
    };
};

export type CreateNewOrganizationMutationVariables = Exact<{
    input: CreateOrganizationDto;
}>;

export type CreateNewOrganizationMutation = {
    __typename?: 'Mutation';
    newOrganization: {
        __typename?: 'Organization';
        id: string;
        name: string;
        contactEmail: string;
        owner: string;
        avatarUrl?: string | null;
        contactPhone?: string | null;
        deletedAt: any;
        address: {
            __typename?: 'Address';
            id: string;
            street?: string | null;
            streetNumber?: string | null;
            unit?: string | null;
            city?: string | null;
            state?: string | null;
            postalCode?: string | null;
            country?: string | null;
            createdAt: any;
            updatedAt: any;
        };
        createdBy: { __typename?: 'User'; id: string };
        deletedBy: { __typename?: 'User'; id: string };
        updatedBy: { __typename?: 'User'; id: string };
    };
};

export type CreateNewProductMutationVariables = Exact<{
    remoteId?: InputMaybe<Scalars['String']['input']>;
    code: Scalars['String']['input'];
    description: Scalars['String']['input'];
    servingUnit: ServingUnit;
    primaryUnitType: PrimaryUnitType;
    retailCount: Scalars['Float']['input'];
    grossWeightBase: Scalars['Float']['input'];
    holePunchTearNotch: HolePunchTearNotchType;
    holePunchTearNotchDetails: Scalars['String']['input'];
    gtin14: Scalars['String']['input'];
    upc12: Scalars['String']['input'];
    former: Scalars['Float']['input'];
    overlap: Scalars['Float']['input'];
    seal: SealType;
    cutoff: Scalars['Float']['input'];
    weightUnit: UnitOfMeasureDto;
    lengthUnit: UnitOfMeasureDto;
    areaUnit: UnitOfMeasureDto;
    prices: Array<ProductPriceDto> | ProductPriceDto;
    items: ItemDto;
    caseInfo: CaseInfoDto;
    palletInfo: PalletInfoDto;
    displayInfo: DisplayInfoDto;
    shipperInfo: ShipperInfoDto;
    truckloadInfo: TruckloadInfoDto;
}>;

export type CreateNewProductMutation = {
    __typename?: 'Mutation';
    newProduct: {
        __typename?: 'Product';
        id: string;
        code: string;
        description: string;
    };
};

export type CreateNewUserMutationVariables = Exact<{
    input: CreateUserDto;
}>;

export type CreateNewUserMutation = {
    __typename?: 'Mutation';
    newUser: {
        __typename?: 'User';
        id: string;
        email: string;
        firstName: string;
        lastName: string;
        organization?: string | null;
        phone?: string | null;
        roles: Array<UserRole>;
        deletedAt: any;
        createdBy: { __typename?: 'User'; id: string };
        updatedBy: { __typename?: 'User'; id: string };
    };
};

export type DeleteCustomerMutationVariables = Exact<{
    id: Scalars['String']['input'];
}>;

export type DeleteCustomerMutation = {
    __typename?: 'Mutation';
    deleteCustomer: { __typename?: 'DeleteItemResponse'; success: boolean };
};

export type DeleteDocumentMutationVariables = Exact<{
    id: Scalars['String']['input'];
}>;

export type DeleteDocumentMutation = {
    __typename?: 'Mutation';
    deleteDocument: { __typename?: 'DeleteItemResponse'; success: boolean };
};

export type DeleteGeneratedDocumentMutationVariables = Exact<{
    id: Scalars['String']['input'];
}>;

export type DeleteGeneratedDocumentMutation = {
    __typename?: 'Mutation';
    deleteGeneratedDocument: {
        __typename?: 'DeleteItemResponse';
        success: boolean;
    };
};

export type DeleteIntegrationRecordMutationVariables = Exact<{
    id: Scalars['String']['input'];
}>;

export type DeleteIntegrationRecordMutation = {
    __typename?: 'Mutation';
    deleteIntegration: { __typename?: 'DeleteItemResponse'; success: boolean };
};

export type DeleteOrganizationMutationVariables = Exact<{
    id: Scalars['String']['input'];
}>;

export type DeleteOrganizationMutation = {
    __typename?: 'Mutation';
    deleteOrganization: { __typename?: 'DeleteItemResponse'; success: boolean };
};

export type DeleteProductMutationVariables = Exact<{
    id: Scalars['String']['input'];
}>;

export type DeleteProductMutation = {
    __typename?: 'Mutation';
    deleteProduct: { __typename?: 'DeleteItemResponse'; success: boolean };
};

export type DeleteUserMutationVariables = Exact<{
    id: Scalars['String']['input'];
}>;

export type DeleteUserMutation = {
    __typename?: 'Mutation';
    deleteUser: { __typename?: 'DeleteItemResponse'; success: boolean };
};

export type UpdateExistingCustomerMutationVariables = Exact<{
    id: Scalars['String']['input'];
    input: UpdateCustomerDto;
}>;

export type UpdateExistingCustomerMutation = {
    __typename?: 'Mutation';
    updateCustomer: {
        __typename?: 'Customer';
        id: string;
        remoteId?: string | null;
        companyName: string;
        subsidiary: string;
        categoryChannel: string;
        customerServiceRep: string;
        terms: string;
        email: string;
        phone: string;
        fax: string;
        shippingMethod: string;
        priceLevel: string;
        creditLimit: number;
        primaryCurrency: string;
        ediCapable: boolean;
    };
};

export type UpdateExistingDocumentMutationVariables = Exact<{
    id: Scalars['String']['input'];
    input: UpdateDocumentDto;
}>;

export type UpdateExistingDocumentMutation = {
    __typename?: 'Mutation';
    updateDocument: {
        __typename?: 'UploadedDocument';
        id: string;
        downloadUrl?: string | null;
        name: string;
        deletedOn?: any | null;
        uploadedBy?: string | null;
        documentStatus: DocumentStatus;
        organization: { __typename?: 'Organization'; id: string; name: string };
        customer: { __typename?: 'Customer'; id: string; companyName: string };
        sheets?: Array<{
            __typename?: 'DocumentSheet';
            index: number;
            name: string;
            documentType: DocumentType;
            documentStatus: DocumentStatus;
            headerRow: number;
            columns?: Array<{
                __typename?: 'SheetColumn';
                index: number;
                name: string;
                mapping: {
                    __typename?: 'SheetColumnMapping';
                    connectAIColumn: string;
                    extraVerification?: boolean | null;
                };
            }> | null;
        }> | null;
    };
};

export type UpdateExistingGeneratedDocumentMutationVariables = Exact<{
    id: Scalars['String']['input'];
    input: UpdateGeneratedDocumentDto;
}>;

export type UpdateExistingGeneratedDocumentMutation = {
    __typename?: 'Mutation';
    updateGeneratedDocument: {
        __typename?: 'GeneratedDocument';
        id: string;
        organization: { __typename?: 'Organization'; id: string };
        customer: { __typename?: 'Customer'; id: string };
        document: { __typename?: 'UploadedDocument'; id: string };
        product: {
            __typename?: 'Product';
            id: string;
            code: string;
            description: string;
            servingUnit: ServingUnit;
            primaryUnitType: PrimaryUnitType;
            retailCount: number;
            grossWeightBase: number;
            weightUnit: {
                __typename?: 'UnitOfMeasure';
                id: string;
                type: UnitType;
                unit: string;
            };
            lengthUnit: {
                __typename?: 'UnitOfMeasure';
                id: string;
                type: UnitType;
                unit: string;
            };
            areaUnit: {
                __typename?: 'UnitOfMeasure';
                id: string;
                type: UnitType;
                unit: string;
            };
            items: {
                __typename?: 'Item';
                id: string;
                flavor: string;
                guaranteedShelfLife: number;
                shelfLifeDays: number;
                lengthLeftToRight: number;
                widthFrontToBack: number;
                heightTopToBottom: number;
                netWeight: number;
                sellableWeight: number;
                filmWebWidth: number;
                weightUnit: {
                    __typename?: 'UnitOfMeasure';
                    id: string;
                    type: UnitType;
                    unit: string;
                };
                lengthUnit: {
                    __typename?: 'UnitOfMeasure';
                    id: string;
                    type: UnitType;
                    unit: string;
                };
            };
            caseInfo: {
                __typename?: 'CaseInfo';
                id: string;
                casePack: string;
                masterCaseCube: number;
                masterCaseLengthLeftToRight: number;
                masterCaseWidthFrontToBack: number;
                masterCaseHeightTopToBottom: number;
                masterCaseNetWt: number;
                weightUnit: {
                    __typename?: 'UnitOfMeasure';
                    id: string;
                    type: UnitType;
                    unit: string;
                };
                lengthUnit: {
                    __typename?: 'UnitOfMeasure';
                    id: string;
                    type: UnitType;
                    unit: string;
                };
                areaUnit: {
                    __typename?: 'UnitOfMeasure';
                    id: string;
                    type: UnitType;
                    unit: string;
                };
            };
            palletInfo: {
                __typename?: 'PalletInfo';
                id: string;
                perTruckload: number;
                perContainer: number;
                ti: number;
                hi: number;
                casesOrUnitsPer: number;
                grossWeightWoPallet: number;
                grossWeight: number;
                heightTopToBottomWoPallet: number;
                netWeight: number;
                heightTopToBottom: number;
                widthFrontToBack: number;
                lengthLeftToRight: number;
                cube: number;
                cubeCFWoPallet: number;
                weightUnit: {
                    __typename?: 'UnitOfMeasure';
                    id: string;
                    type: UnitType;
                    unit: string;
                };
                lengthUnit: {
                    __typename?: 'UnitOfMeasure';
                    id: string;
                    type: UnitType;
                    unit: string;
                };
                areaUnit: {
                    __typename?: 'UnitOfMeasure';
                    id: string;
                    type: UnitType;
                    unit: string;
                };
            };
            displayInfo: {
                __typename?: 'DisplayInfo';
                id: string;
                lengthLeftToRight: number;
                widthFrontToBack: number;
                heightTopToBottom: number;
                netWeight: number;
                weightUnit: {
                    __typename?: 'UnitOfMeasure';
                    id: string;
                    type: UnitType;
                    unit: string;
                };
                lengthUnit: {
                    __typename?: 'UnitOfMeasure';
                    id: string;
                    type: UnitType;
                    unit: string;
                };
            };
            shipperInfo: {
                __typename?: 'ShipperInfo';
                id: string;
                lengthLeftToRight: number;
                widthFrontToBack: number;
                heightTopToBottom: number;
                netWeight: number;
                cube: number;
                weightUnit: {
                    __typename?: 'UnitOfMeasure';
                    id: string;
                    type: UnitType;
                    unit: string;
                };
                lengthUnit: {
                    __typename?: 'UnitOfMeasure';
                    id: string;
                    type: UnitType;
                    unit: string;
                };
                areaUnit: {
                    __typename?: 'UnitOfMeasure';
                    id: string;
                    type: UnitType;
                    unit: string;
                };
            };
            truckloadInfo: {
                __typename?: 'TruckloadInfo';
                id: string;
                grossWeighttPer: number;
                unitsPer: number;
                stackLevel: StackLevel;
                weightUnit: {
                    __typename?: 'UnitOfMeasure';
                    id: string;
                    type: UnitType;
                    unit: string;
                };
            };
        };
        customValues: Array<{
            __typename?: 'CustomValue';
            columnIndex: number;
            value: string;
        }>;
    };
};

export type UpdateExistingOrganizationMutationVariables = Exact<{
    id: Scalars['String']['input'];
    input: UpdateOrganizationDto;
}>;

export type UpdateExistingOrganizationMutation = {
    __typename?: 'Mutation';
    updateOrganization: {
        __typename?: 'Organization';
        id: string;
        name: string;
        contactEmail: string;
        owner: string;
        avatarUrl?: string | null;
        contactPhone?: string | null;
        deletedAt: any;
        address: {
            __typename?: 'Address';
            id: string;
            street?: string | null;
            streetNumber?: string | null;
            unit?: string | null;
            city?: string | null;
            state?: string | null;
            postalCode?: string | null;
            country?: string | null;
            createdAt: any;
            updatedAt: any;
        };
        createdBy: { __typename?: 'User'; id: string };
        deletedBy: { __typename?: 'User'; id: string };
        updatedBy: { __typename?: 'User'; id: string };
    };
};

export type UpdateExistingProductMutationVariables = Exact<{
    id: Scalars['String']['input'];
    remoteId?: InputMaybe<Scalars['String']['input']>;
    code?: InputMaybe<Scalars['String']['input']>;
    description?: InputMaybe<Scalars['String']['input']>;
    servingUnit?: InputMaybe<ServingUnit>;
    primaryUnitType?: InputMaybe<PrimaryUnitType>;
    retailCount?: InputMaybe<Scalars['Float']['input']>;
    grossWeightBase?: InputMaybe<Scalars['Float']['input']>;
    holePunchTearNotch?: InputMaybe<HolePunchTearNotchType>;
    holePunchTearNotchDetails?: InputMaybe<Scalars['String']['input']>;
    gtin14?: InputMaybe<Scalars['String']['input']>;
    upc12?: InputMaybe<Scalars['String']['input']>;
    former?: InputMaybe<Scalars['Float']['input']>;
    overlap?: InputMaybe<Scalars['Float']['input']>;
    seal?: InputMaybe<SealType>;
    cutoff?: InputMaybe<Scalars['Float']['input']>;
    weightUnit?: InputMaybe<UnitOfMeasureDto>;
    lengthUnit?: InputMaybe<UnitOfMeasureDto>;
    areaUnit?: InputMaybe<UnitOfMeasureDto>;
    prices?: InputMaybe<Array<ProductPriceDto> | ProductPriceDto>;
    items?: InputMaybe<ItemDto>;
    caseInfo?: InputMaybe<CaseInfoDto>;
    palletInfo?: InputMaybe<PalletInfoDto>;
    displayInfo?: InputMaybe<DisplayInfoDto>;
    shipperInfo?: InputMaybe<ShipperInfoDto>;
    truckloadInfo?: InputMaybe<TruckloadInfoDto>;
}>;

export type UpdateExistingProductMutation = {
    __typename?: 'Mutation';
    updateProduct: {
        __typename?: 'Product';
        id: string;
        code: string;
        description: string;
    };
};

export type UpdateExistingUserMutationVariables = Exact<{
    id: Scalars['String']['input'];
    input: UpdateUserDto;
}>;

export type UpdateExistingUserMutation = {
    __typename?: 'Mutation';
    updateUser: {
        __typename?: 'User';
        id: string;
        email: string;
        firstName: string;
        lastName: string;
        organization?: string | null;
        phone?: string | null;
        roles: Array<UserRole>;
        deletedAt: any;
        createdBy: { __typename?: 'User'; id: string };
        updatedBy: { __typename?: 'User'; id: string };
    };
};

export type UpdateExistingIntegrationRecordMutationVariables = Exact<{
    id: Scalars['String']['input'];
    input: UpdateIntegrationRecordDto;
}>;

export type UpdateExistingIntegrationRecordMutation = {
    __typename?: 'Mutation';
    updateIntegration: {
        __typename?: 'IntegrationRecord';
        id: string;
        name: string;
        integrationType: string;
        organizationId: string;
        details:
            | {
                  __typename?: 'NetsuiteIntegrationDetails';
                  accountId: string;
                  clientId: string;
                  clientSecret: string;
                  companyUrl: string;
              }
            | {
                  __typename?: 'OtherIntegrationDetails';
                  endPointUri: string;
                  token: string;
              };
    };
};

export type CheckQueryVariables = Exact<{ [key: string]: never }>;

export type CheckQuery = {
    __typename?: 'Query';
    check: {
        __typename?: 'ReadOnlyMode';
        info: {
            __typename?: 'ReadOnlyInfo';
            organizations: boolean;
            users: boolean;
            integrationRecords: boolean;
            products: boolean;
        };
    };
};

export type GetCurrentUserQueryVariables = Exact<{ [key: string]: never }>;

export type GetCurrentUserQuery = {
    __typename?: 'Query';
    me: {
        __typename?: 'User';
        id: string;
        firstName: string;
        lastName: string;
        email: string;
        phone?: string | null;
        organization?: string | null;
        roles: Array<UserRole>;
    };
};

export type GetCustomerByIdQueryVariables = Exact<{
    id: Scalars['String']['input'];
}>;

export type GetCustomerByIdQuery = {
    __typename?: 'Query';
    customer: {
        __typename?: 'Customer';
        id: string;
        remoteId?: string | null;
        companyName: string;
        subsidiary: string;
        categoryChannel: string;
        customerServiceRep: string;
        terms: string;
        email: string;
        phone: string;
        fax: string;
        shippingMethod: string;
        priceLevel: string;
        creditLimit: number;
        primaryCurrency: string;
        ediCapable: boolean;
    };
};

export type GetAllCustomersQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllCustomersQuery = {
    __typename?: 'Query';
    customers: Array<{
        __typename?: 'Customer';
        id: string;
        remoteId?: string | null;
        companyName: string;
        subsidiary: string;
        categoryChannel: string;
        customerServiceRep: string;
        terms: string;
        email: string;
        phone: string;
        fax: string;
        shippingMethod: string;
        priceLevel: string;
        creditLimit: number;
        primaryCurrency: string;
        ediCapable: boolean;
    }>;
};

export type GetDocumentByIdQueryVariables = Exact<{
    id: Scalars['String']['input'];
}>;

export type GetDocumentByIdQuery = {
    __typename?: 'Query';
    document: {
        __typename?: 'UploadedDocument';
        id: string;
        downloadUrl?: string | null;
        name: string;
        deletedOn?: any | null;
        uploadedBy?: string | null;
        documentStatus: DocumentStatus;
        organization: { __typename?: 'Organization'; id: string; name: string };
        customer: { __typename?: 'Customer'; id: string; companyName: string };
        sheets?: Array<{
            __typename?: 'DocumentSheet';
            index: number;
            name: string;
            documentType: DocumentType;
            documentStatus: DocumentStatus;
            headerRow: number;
            columns?: Array<{
                __typename?: 'SheetColumn';
                index: number;
                name: string;
                mapping: {
                    __typename?: 'SheetColumnMapping';
                    connectAIColumn: string;
                    extraVerification?: boolean | null;
                };
            }> | null;
        }> | null;
    };
};

export type GetAllDocumentsQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllDocumentsQuery = {
    __typename?: 'Query';
    documents: Array<{
        __typename?: 'UploadedDocument';
        id: string;
        downloadUrl?: string | null;
        name: string;
        deletedOn?: any | null;
        uploadedBy?: string | null;
        documentStatus: DocumentStatus;
        organization: { __typename?: 'Organization'; id: string; name: string };
        customer: { __typename?: 'Customer'; id: string; companyName: string };
        sheets?: Array<{
            __typename?: 'DocumentSheet';
            index: number;
            name: string;
            documentType: DocumentType;
            documentStatus: DocumentStatus;
            headerRow: number;
            columns?: Array<{
                __typename?: 'SheetColumn';
                index: number;
                name: string;
                mapping: {
                    __typename?: 'SheetColumnMapping';
                    connectAIColumn: string;
                    extraVerification?: boolean | null;
                };
            }> | null;
        }> | null;
    }>;
};

export type GetGeneratedDocumentByIdQueryVariables = Exact<{
    id: Scalars['String']['input'];
}>;

export type GetGeneratedDocumentByIdQuery = {
    __typename?: 'Query';
    generatedDocument: {
        __typename?: 'GeneratedDocument';
        id: string;
        organization: { __typename?: 'Organization'; id: string };
        customer: { __typename?: 'Customer'; id: string };
        document: { __typename?: 'UploadedDocument'; id: string };
        product: {
            __typename?: 'Product';
            id: string;
            code: string;
            description: string;
            servingUnit: ServingUnit;
            primaryUnitType: PrimaryUnitType;
            retailCount: number;
            grossWeightBase: number;
            weightUnit: {
                __typename?: 'UnitOfMeasure';
                id: string;
                type: UnitType;
                unit: string;
            };
            lengthUnit: {
                __typename?: 'UnitOfMeasure';
                id: string;
                type: UnitType;
                unit: string;
            };
            areaUnit: {
                __typename?: 'UnitOfMeasure';
                id: string;
                type: UnitType;
                unit: string;
            };
            items: {
                __typename?: 'Item';
                id: string;
                flavor: string;
                guaranteedShelfLife: number;
                shelfLifeDays: number;
                lengthLeftToRight: number;
                widthFrontToBack: number;
                heightTopToBottom: number;
                netWeight: number;
                sellableWeight: number;
                filmWebWidth: number;
                weightUnit: {
                    __typename?: 'UnitOfMeasure';
                    id: string;
                    type: UnitType;
                    unit: string;
                };
                lengthUnit: {
                    __typename?: 'UnitOfMeasure';
                    id: string;
                    type: UnitType;
                    unit: string;
                };
            };
            caseInfo: {
                __typename?: 'CaseInfo';
                id: string;
                casePack: string;
                masterCaseCube: number;
                masterCaseLengthLeftToRight: number;
                masterCaseWidthFrontToBack: number;
                masterCaseHeightTopToBottom: number;
                masterCaseNetWt: number;
                weightUnit: {
                    __typename?: 'UnitOfMeasure';
                    id: string;
                    type: UnitType;
                    unit: string;
                };
                lengthUnit: {
                    __typename?: 'UnitOfMeasure';
                    id: string;
                    type: UnitType;
                    unit: string;
                };
                areaUnit: {
                    __typename?: 'UnitOfMeasure';
                    id: string;
                    type: UnitType;
                    unit: string;
                };
            };
            palletInfo: {
                __typename?: 'PalletInfo';
                id: string;
                perTruckload: number;
                perContainer: number;
                ti: number;
                hi: number;
                casesOrUnitsPer: number;
                grossWeightWoPallet: number;
                grossWeight: number;
                heightTopToBottomWoPallet: number;
                netWeight: number;
                heightTopToBottom: number;
                widthFrontToBack: number;
                lengthLeftToRight: number;
                cube: number;
                cubeCFWoPallet: number;
                weightUnit: {
                    __typename?: 'UnitOfMeasure';
                    id: string;
                    type: UnitType;
                    unit: string;
                };
                lengthUnit: {
                    __typename?: 'UnitOfMeasure';
                    id: string;
                    type: UnitType;
                    unit: string;
                };
                areaUnit: {
                    __typename?: 'UnitOfMeasure';
                    id: string;
                    type: UnitType;
                    unit: string;
                };
            };
            displayInfo: {
                __typename?: 'DisplayInfo';
                id: string;
                lengthLeftToRight: number;
                widthFrontToBack: number;
                heightTopToBottom: number;
                netWeight: number;
                weightUnit: {
                    __typename?: 'UnitOfMeasure';
                    id: string;
                    type: UnitType;
                    unit: string;
                };
                lengthUnit: {
                    __typename?: 'UnitOfMeasure';
                    id: string;
                    type: UnitType;
                    unit: string;
                };
            };
            shipperInfo: {
                __typename?: 'ShipperInfo';
                id: string;
                lengthLeftToRight: number;
                widthFrontToBack: number;
                heightTopToBottom: number;
                netWeight: number;
                cube: number;
                weightUnit: {
                    __typename?: 'UnitOfMeasure';
                    id: string;
                    type: UnitType;
                    unit: string;
                };
                lengthUnit: {
                    __typename?: 'UnitOfMeasure';
                    id: string;
                    type: UnitType;
                    unit: string;
                };
                areaUnit: {
                    __typename?: 'UnitOfMeasure';
                    id: string;
                    type: UnitType;
                    unit: string;
                };
            };
            truckloadInfo: {
                __typename?: 'TruckloadInfo';
                id: string;
                grossWeighttPer: number;
                unitsPer: number;
                stackLevel: StackLevel;
                weightUnit: {
                    __typename?: 'UnitOfMeasure';
                    id: string;
                    type: UnitType;
                    unit: string;
                };
            };
        };
        customValues: Array<{
            __typename?: 'CustomValue';
            columnIndex: number;
            value: string;
        }>;
    };
};

export type GetAllGeneratedDocumentsQueryVariables = Exact<{
    [key: string]: never;
}>;

export type GetAllGeneratedDocumentsQuery = {
    __typename?: 'Query';
    generatedDocuments: Array<{
        __typename?: 'GeneratedDocument';
        id: string;
        organization: { __typename?: 'Organization'; id: string };
        customer: { __typename?: 'Customer'; id: string };
        document: { __typename?: 'UploadedDocument'; id: string };
        product: {
            __typename?: 'Product';
            id: string;
            code: string;
            description: string;
            servingUnit: ServingUnit;
            primaryUnitType: PrimaryUnitType;
            retailCount: number;
            grossWeightBase: number;
            weightUnit: {
                __typename?: 'UnitOfMeasure';
                id: string;
                type: UnitType;
                unit: string;
            };
            lengthUnit: {
                __typename?: 'UnitOfMeasure';
                id: string;
                type: UnitType;
                unit: string;
            };
            areaUnit: {
                __typename?: 'UnitOfMeasure';
                id: string;
                type: UnitType;
                unit: string;
            };
            items: {
                __typename?: 'Item';
                id: string;
                flavor: string;
                guaranteedShelfLife: number;
                shelfLifeDays: number;
                lengthLeftToRight: number;
                widthFrontToBack: number;
                heightTopToBottom: number;
                netWeight: number;
                sellableWeight: number;
                filmWebWidth: number;
                weightUnit: {
                    __typename?: 'UnitOfMeasure';
                    id: string;
                    type: UnitType;
                    unit: string;
                };
                lengthUnit: {
                    __typename?: 'UnitOfMeasure';
                    id: string;
                    type: UnitType;
                    unit: string;
                };
            };
            caseInfo: {
                __typename?: 'CaseInfo';
                id: string;
                casePack: string;
                masterCaseCube: number;
                masterCaseLengthLeftToRight: number;
                masterCaseWidthFrontToBack: number;
                masterCaseHeightTopToBottom: number;
                masterCaseNetWt: number;
                weightUnit: {
                    __typename?: 'UnitOfMeasure';
                    id: string;
                    type: UnitType;
                    unit: string;
                };
                lengthUnit: {
                    __typename?: 'UnitOfMeasure';
                    id: string;
                    type: UnitType;
                    unit: string;
                };
                areaUnit: {
                    __typename?: 'UnitOfMeasure';
                    id: string;
                    type: UnitType;
                    unit: string;
                };
            };
            palletInfo: {
                __typename?: 'PalletInfo';
                id: string;
                perTruckload: number;
                perContainer: number;
                ti: number;
                hi: number;
                casesOrUnitsPer: number;
                grossWeightWoPallet: number;
                grossWeight: number;
                heightTopToBottomWoPallet: number;
                netWeight: number;
                heightTopToBottom: number;
                widthFrontToBack: number;
                lengthLeftToRight: number;
                cube: number;
                cubeCFWoPallet: number;
                weightUnit: {
                    __typename?: 'UnitOfMeasure';
                    id: string;
                    type: UnitType;
                    unit: string;
                };
                lengthUnit: {
                    __typename?: 'UnitOfMeasure';
                    id: string;
                    type: UnitType;
                    unit: string;
                };
                areaUnit: {
                    __typename?: 'UnitOfMeasure';
                    id: string;
                    type: UnitType;
                    unit: string;
                };
            };
            displayInfo: {
                __typename?: 'DisplayInfo';
                id: string;
                lengthLeftToRight: number;
                widthFrontToBack: number;
                heightTopToBottom: number;
                netWeight: number;
                weightUnit: {
                    __typename?: 'UnitOfMeasure';
                    id: string;
                    type: UnitType;
                    unit: string;
                };
                lengthUnit: {
                    __typename?: 'UnitOfMeasure';
                    id: string;
                    type: UnitType;
                    unit: string;
                };
            };
            shipperInfo: {
                __typename?: 'ShipperInfo';
                id: string;
                lengthLeftToRight: number;
                widthFrontToBack: number;
                heightTopToBottom: number;
                netWeight: number;
                cube: number;
                weightUnit: {
                    __typename?: 'UnitOfMeasure';
                    id: string;
                    type: UnitType;
                    unit: string;
                };
                lengthUnit: {
                    __typename?: 'UnitOfMeasure';
                    id: string;
                    type: UnitType;
                    unit: string;
                };
                areaUnit: {
                    __typename?: 'UnitOfMeasure';
                    id: string;
                    type: UnitType;
                    unit: string;
                };
            };
            truckloadInfo: {
                __typename?: 'TruckloadInfo';
                id: string;
                grossWeighttPer: number;
                unitsPer: number;
                stackLevel: StackLevel;
                weightUnit: {
                    __typename?: 'UnitOfMeasure';
                    id: string;
                    type: UnitType;
                    unit: string;
                };
            };
        };
        customValues: Array<{
            __typename?: 'CustomValue';
            columnIndex: number;
            value: string;
        }>;
    }>;
};

export type GetIntegrationRecordByIdQueryVariables = Exact<{
    id: Scalars['String']['input'];
}>;

export type GetIntegrationRecordByIdQuery = {
    __typename?: 'Query';
    integration: {
        __typename?: 'IntegrationRecord';
        id: string;
        name: string;
        integrationType: string;
        organizationId: string;
        details:
            | {
                  __typename?: 'NetsuiteIntegrationDetails';
                  accountId: string;
                  clientId: string;
                  clientSecret: string;
                  companyUrl: string;
              }
            | {
                  __typename?: 'OtherIntegrationDetails';
                  endPointUri: string;
                  token: string;
              };
    };
};

export type GetAllIntegrationRecordsQueryVariables = Exact<{
    [key: string]: never;
}>;

export type GetAllIntegrationRecordsQuery = {
    __typename?: 'Query';
    integrations: Array<{
        __typename?: 'IntegrationRecord';
        id: string;
        name: string;
        integrationType: string;
        organizationId: string;
        details:
            | {
                  __typename?: 'NetsuiteIntegrationDetails';
                  accountId: string;
                  clientId: string;
                  clientSecret: string;
                  companyUrl: string;
              }
            | {
                  __typename?: 'OtherIntegrationDetails';
                  endPointUri: string;
                  token: string;
              };
    }>;
};

export type GetMapValuesQueryVariables = Exact<{ [key: string]: never }>;

export type GetMapValuesQuery = {
    __typename?: 'Query';
    mapValues: Array<{
        __typename?: 'MappingValue';
        label: string;
        value: string;
    }>;
};

export type GetOrganizationByIdQueryVariables = Exact<{
    id: Scalars['String']['input'];
}>;

export type GetOrganizationByIdQuery = {
    __typename?: 'Query';
    Organization: {
        __typename?: 'Organization';
        id: string;
        name: string;
        contactEmail: string;
        owner: string;
        avatarUrl?: string | null;
        contactPhone?: string | null;
        address: {
            __typename?: 'Address';
            id: string;
            street?: string | null;
            streetNumber?: string | null;
            unit?: string | null;
            city?: string | null;
            state?: string | null;
            postalCode?: string | null;
            country?: string | null;
            createdAt: any;
            updatedAt: any;
        };
    };
};

export type GetAllOrganizationsQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllOrganizationsQuery = {
    __typename?: 'Query';
    Organizations: Array<{
        __typename?: 'Organization';
        id: string;
        name: string;
        contactEmail: string;
        avatarUrl?: string | null;
        contactPhone?: string | null;
        owner: string;
        address: {
            __typename?: 'Address';
            id: string;
            street?: string | null;
            streetNumber?: string | null;
            unit?: string | null;
            city?: string | null;
            state?: string | null;
            postalCode?: string | null;
            country?: string | null;
            createdAt: any;
            updatedAt: any;
        };
    }>;
};

export type GetProductByIdQueryVariables = Exact<{
    id: Scalars['String']['input'];
}>;

export type GetProductByIdQuery = {
    __typename?: 'Query';
    Product: {
        __typename?: 'Product';
        id: string;
        remoteId?: string | null;
        code: string;
        description: string;
        servingUnit: ServingUnit;
        primaryUnitType: PrimaryUnitType;
        retailCount: number;
        grossWeightBase: number;
        weightUnit: {
            __typename?: 'UnitOfMeasure';
            id: string;
            type: UnitType;
            unit: string;
        };
        lengthUnit: {
            __typename?: 'UnitOfMeasure';
            id: string;
            type: UnitType;
            unit: string;
        };
        areaUnit: {
            __typename?: 'UnitOfMeasure';
            id: string;
            type: UnitType;
            unit: string;
        };
        prices: Array<{
            __typename?: 'ProductPrice';
            id: string;
            price: number;
            currency: CurrencyType;
            priceLevel: string;
            priceQuantity?: number | null;
        }>;
        items: {
            __typename?: 'Item';
            id: string;
            flavor: string;
            guaranteedShelfLife: number;
            shelfLifeDays: number;
            lengthLeftToRight: number;
            widthFrontToBack: number;
            heightTopToBottom: number;
            netWeight: number;
            sellableWeight: number;
            filmWebWidth: number;
            weightUnit: {
                __typename?: 'UnitOfMeasure';
                id: string;
                type: UnitType;
                unit: string;
            };
            lengthUnit: {
                __typename?: 'UnitOfMeasure';
                id: string;
                type: UnitType;
                unit: string;
            };
        };
        caseInfo: {
            __typename?: 'CaseInfo';
            id: string;
            casePack: string;
            masterCaseCube: number;
            masterCaseLengthLeftToRight: number;
            masterCaseWidthFrontToBack: number;
            masterCaseHeightTopToBottom: number;
            masterCaseNetWt: number;
            weightUnit: {
                __typename?: 'UnitOfMeasure';
                id: string;
                type: UnitType;
                unit: string;
            };
            lengthUnit: {
                __typename?: 'UnitOfMeasure';
                id: string;
                type: UnitType;
                unit: string;
            };
            areaUnit: {
                __typename?: 'UnitOfMeasure';
                id: string;
                type: UnitType;
                unit: string;
            };
        };
        palletInfo: {
            __typename?: 'PalletInfo';
            id: string;
            perTruckload: number;
            perContainer: number;
            ti: number;
            hi: number;
            casesOrUnitsPer: number;
            grossWeightWoPallet: number;
            grossWeight: number;
            heightTopToBottomWoPallet: number;
            netWeight: number;
            heightTopToBottom: number;
            widthFrontToBack: number;
            lengthLeftToRight: number;
            cube: number;
            cubeCFWoPallet: number;
            weightUnit: {
                __typename?: 'UnitOfMeasure';
                id: string;
                type: UnitType;
                unit: string;
            };
            lengthUnit: {
                __typename?: 'UnitOfMeasure';
                id: string;
                type: UnitType;
                unit: string;
            };
            areaUnit: {
                __typename?: 'UnitOfMeasure';
                id: string;
                type: UnitType;
                unit: string;
            };
        };
        displayInfo: {
            __typename?: 'DisplayInfo';
            id: string;
            lengthLeftToRight: number;
            widthFrontToBack: number;
            heightTopToBottom: number;
            netWeight: number;
            weightUnit: {
                __typename?: 'UnitOfMeasure';
                id: string;
                type: UnitType;
                unit: string;
            };
            lengthUnit: {
                __typename?: 'UnitOfMeasure';
                id: string;
                type: UnitType;
                unit: string;
            };
        };
        shipperInfo: {
            __typename?: 'ShipperInfo';
            id: string;
            lengthLeftToRight: number;
            widthFrontToBack: number;
            heightTopToBottom: number;
            netWeight: number;
            cube: number;
            weightUnit: {
                __typename?: 'UnitOfMeasure';
                id: string;
                type: UnitType;
                unit: string;
            };
            lengthUnit: {
                __typename?: 'UnitOfMeasure';
                id: string;
                type: UnitType;
                unit: string;
            };
            areaUnit: {
                __typename?: 'UnitOfMeasure';
                id: string;
                type: UnitType;
                unit: string;
            };
        };
        truckloadInfo: {
            __typename?: 'TruckloadInfo';
            id: string;
            grossWeighttPer: number;
            unitsPer: number;
            stackLevel: StackLevel;
            weightUnit: {
                __typename?: 'UnitOfMeasure';
                id: string;
                type: UnitType;
                unit: string;
            };
        };
    };
};

export type GetAllProductsQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllProductsQuery = {
    __typename?: 'Query';
    Products: Array<{
        __typename?: 'Product';
        id: string;
        remoteId?: string | null;
        code: string;
        description: string;
        servingUnit: ServingUnit;
        primaryUnitType: PrimaryUnitType;
        retailCount: number;
        grossWeightBase: number;
        holePunchTearNotch: HolePunchTearNotchType;
        holePunchTearNotchDetails: string;
        gtin14: string;
        upc12: string;
        former: number;
        overlap: number;
        seal: SealType;
        cutoff: number;
        prices: Array<{
            __typename?: 'ProductPrice';
            id: string;
            price: number;
            currency: CurrencyType;
            priceLevel: string;
            priceQuantity?: number | null;
        }>;
        weightUnit: {
            __typename?: 'UnitOfMeasure';
            id: string;
            type: UnitType;
            unit: string;
        };
        lengthUnit: {
            __typename?: 'UnitOfMeasure';
            id: string;
            type: UnitType;
            unit: string;
        };
        areaUnit: {
            __typename?: 'UnitOfMeasure';
            id: string;
            type: UnitType;
            unit: string;
        };
        items: {
            __typename?: 'Item';
            id: string;
            flavor: string;
            guaranteedShelfLife: number;
            shelfLifeDays: number;
            lengthLeftToRight: number;
            widthFrontToBack: number;
            heightTopToBottom: number;
            netWeight: number;
            sellableWeight: number;
            filmWebWidth: number;
            weightUnit: {
                __typename?: 'UnitOfMeasure';
                id: string;
                type: UnitType;
                unit: string;
            };
            lengthUnit: {
                __typename?: 'UnitOfMeasure';
                id: string;
                type: UnitType;
                unit: string;
            };
        };
        caseInfo: {
            __typename?: 'CaseInfo';
            id: string;
            casePack: string;
            masterCaseCube: number;
            masterCaseLengthLeftToRight: number;
            masterCaseWidthFrontToBack: number;
            masterCaseHeightTopToBottom: number;
            masterCaseNetWt: number;
            weightUnit: {
                __typename?: 'UnitOfMeasure';
                id: string;
                type: UnitType;
                unit: string;
            };
            lengthUnit: {
                __typename?: 'UnitOfMeasure';
                id: string;
                type: UnitType;
                unit: string;
            };
            areaUnit: {
                __typename?: 'UnitOfMeasure';
                id: string;
                type: UnitType;
                unit: string;
            };
        };
        palletInfo: {
            __typename?: 'PalletInfo';
            id: string;
            perTruckload: number;
            perContainer: number;
            ti: number;
            hi: number;
            casesOrUnitsPer: number;
            grossWeightWoPallet: number;
            grossWeight: number;
            heightTopToBottomWoPallet: number;
            netWeight: number;
            heightTopToBottom: number;
            widthFrontToBack: number;
            lengthLeftToRight: number;
            cube: number;
            cubeCFWoPallet: number;
            weightUnit: {
                __typename?: 'UnitOfMeasure';
                id: string;
                type: UnitType;
                unit: string;
            };
            lengthUnit: {
                __typename?: 'UnitOfMeasure';
                id: string;
                type: UnitType;
                unit: string;
            };
            areaUnit: {
                __typename?: 'UnitOfMeasure';
                id: string;
                type: UnitType;
                unit: string;
            };
        };
        displayInfo: {
            __typename?: 'DisplayInfo';
            id: string;
            lengthLeftToRight: number;
            widthFrontToBack: number;
            heightTopToBottom: number;
            netWeight: number;
            weightUnit: {
                __typename?: 'UnitOfMeasure';
                id: string;
                type: UnitType;
                unit: string;
            };
            lengthUnit: {
                __typename?: 'UnitOfMeasure';
                id: string;
                type: UnitType;
                unit: string;
            };
        };
        shipperInfo: {
            __typename?: 'ShipperInfo';
            id: string;
            lengthLeftToRight: number;
            widthFrontToBack: number;
            heightTopToBottom: number;
            netWeight: number;
            cube: number;
            weightUnit: {
                __typename?: 'UnitOfMeasure';
                id: string;
                type: UnitType;
                unit: string;
            };
            lengthUnit: {
                __typename?: 'UnitOfMeasure';
                id: string;
                type: UnitType;
                unit: string;
            };
            areaUnit: {
                __typename?: 'UnitOfMeasure';
                id: string;
                type: UnitType;
                unit: string;
            };
        };
        truckloadInfo: {
            __typename?: 'TruckloadInfo';
            id: string;
            grossWeighttPer: number;
            unitsPer: number;
            stackLevel: StackLevel;
            weightUnit: {
                __typename?: 'UnitOfMeasure';
                id: string;
                type: UnitType;
                unit: string;
            };
        };
    }>;
};

export type GetUserByIdQueryVariables = Exact<{
    id: Scalars['String']['input'];
}>;

export type GetUserByIdQuery = {
    __typename?: 'Query';
    User: {
        __typename?: 'User';
        id: string;
        email: string;
        firstName: string;
        lastName: string;
        organization?: string | null;
        phone?: string | null;
        avatarUrl?: string | null;
        jobTitle?: string | null;
        roles: Array<UserRole>;
        deletedAt: any;
        createdBy: { __typename?: 'User'; id: string };
        updatedBy: { __typename?: 'User'; id: string };
    };
};

export type GetAllUsersQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllUsersQuery = {
    __typename?: 'Query';
    Users: Array<{
        __typename?: 'User';
        id: string;
        email: string;
        firstName: string;
        lastName: string;
        organization?: string | null;
        phone?: string | null;
        avatarUrl?: string | null;
        jobTitle?: string | null;
        roles: Array<UserRole>;
        deletedAt: any;
        createdBy: { __typename?: 'User'; id: string };
        updatedBy: { __typename?: 'User'; id: string };
    }>;
};

export const CreateNewCustomerDocument = gql`
    mutation createNewCustomer($input: CreateCustomerDto!) {
        newCustomer(input: $input) {
            id
            remoteId
            companyName
            subsidiary
            categoryChannel
            customerServiceRep
            terms
            email
            phone
            fax
            shippingMethod
            priceLevel
            creditLimit
            primaryCurrency
            ediCapable
        }
    }
`;
export type CreateNewCustomerMutationFn = Apollo.MutationFunction<
    CreateNewCustomerMutation,
    CreateNewCustomerMutationVariables
>;

/**
 * __useCreateNewCustomerMutation__
 *
 * To run a mutation, you first call `useCreateNewCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNewCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNewCustomerMutation, { data, loading, error }] = useCreateNewCustomerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateNewCustomerMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreateNewCustomerMutation,
        CreateNewCustomerMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        CreateNewCustomerMutation,
        CreateNewCustomerMutationVariables
    >(CreateNewCustomerDocument, options);
}
export type CreateNewCustomerMutationHookResult = ReturnType<
    typeof useCreateNewCustomerMutation
>;
export type CreateNewCustomerMutationResult =
    Apollo.MutationResult<CreateNewCustomerMutation>;
export type CreateNewCustomerMutationOptions = Apollo.BaseMutationOptions<
    CreateNewCustomerMutation,
    CreateNewCustomerMutationVariables
>;
export const CreateNewDocumentDocument = gql`
    mutation createNewDocument($input: CreateDocumentDto!, $file: Upload!) {
        newDocument(input: $input, file: $file) {
            id
            organization {
                ... on Organization {
                    id
                    name
                }
            }
            customer {
                ... on Customer {
                    id
                    companyName
                }
            }
            downloadUrl
            name
            deletedOn
            uploadedBy
            documentStatus
            sheets {
                index
                name
                documentType
                documentStatus
                headerRow
                columns {
                    index
                    name
                    mapping {
                        connectAIColumn
                        extraVerification
                    }
                }
            }
        }
    }
`;
export type CreateNewDocumentMutationFn = Apollo.MutationFunction<
    CreateNewDocumentMutation,
    CreateNewDocumentMutationVariables
>;

/**
 * __useCreateNewDocumentMutation__
 *
 * To run a mutation, you first call `useCreateNewDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNewDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNewDocumentMutation, { data, loading, error }] = useCreateNewDocumentMutation({
 *   variables: {
 *      input: // value for 'input'
 *      file: // value for 'file'
 *   },
 * });
 */
export function useCreateNewDocumentMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreateNewDocumentMutation,
        CreateNewDocumentMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        CreateNewDocumentMutation,
        CreateNewDocumentMutationVariables
    >(CreateNewDocumentDocument, options);
}
export type CreateNewDocumentMutationHookResult = ReturnType<
    typeof useCreateNewDocumentMutation
>;
export type CreateNewDocumentMutationResult =
    Apollo.MutationResult<CreateNewDocumentMutation>;
export type CreateNewDocumentMutationOptions = Apollo.BaseMutationOptions<
    CreateNewDocumentMutation,
    CreateNewDocumentMutationVariables
>;
export const CreateNewGeneratedDocumentDocument = gql`
    mutation createNewGeneratedDocument($input: CreateGeneratedDocumentDto!) {
        newGeneratedDocument(input: $input) {
            id
            organization {
                id
            }
            customer {
                id
            }
            document {
                id
            }
            product {
                id
                code
                description
                servingUnit
                primaryUnitType
                retailCount
                grossWeightBase
                weightUnit {
                    id
                    type
                    unit
                }
                lengthUnit {
                    id
                    type
                    unit
                }
                areaUnit {
                    id
                    type
                    unit
                }
                items {
                    id
                    flavor
                    guaranteedShelfLife
                    shelfLifeDays
                    lengthLeftToRight
                    widthFrontToBack
                    heightTopToBottom
                    netWeight
                    sellableWeight
                    filmWebWidth
                    weightUnit {
                        id
                        type
                        unit
                    }
                    lengthUnit {
                        id
                        type
                        unit
                    }
                }
                caseInfo {
                    id
                    casePack
                    masterCaseCube
                    weightUnit {
                        id
                        type
                        unit
                    }
                    lengthUnit {
                        id
                        type
                        unit
                    }
                    areaUnit {
                        id
                        type
                        unit
                    }
                    masterCaseLengthLeftToRight
                    masterCaseWidthFrontToBack
                    masterCaseHeightTopToBottom
                    masterCaseNetWt
                }
                palletInfo {
                    id
                    perTruckload
                    perContainer
                    ti
                    hi
                    casesOrUnitsPer
                    grossWeightWoPallet
                    grossWeight
                    heightTopToBottomWoPallet
                    netWeight
                    heightTopToBottom
                    widthFrontToBack
                    lengthLeftToRight
                    weightUnit {
                        id
                        type
                        unit
                    }
                    lengthUnit {
                        id
                        type
                        unit
                    }
                    areaUnit {
                        id
                        type
                        unit
                    }
                    cube
                    cubeCFWoPallet
                }
                displayInfo {
                    id
                    lengthLeftToRight
                    widthFrontToBack
                    heightTopToBottom
                    netWeight
                    weightUnit {
                        id
                        type
                        unit
                    }
                    lengthUnit {
                        id
                        type
                        unit
                    }
                }
                shipperInfo {
                    id
                    lengthLeftToRight
                    widthFrontToBack
                    heightTopToBottom
                    netWeight
                    cube
                    weightUnit {
                        id
                        type
                        unit
                    }
                    lengthUnit {
                        id
                        type
                        unit
                    }
                    areaUnit {
                        id
                        type
                        unit
                    }
                }
                truckloadInfo {
                    id
                    grossWeighttPer
                    unitsPer
                    stackLevel
                    weightUnit {
                        id
                        type
                        unit
                    }
                }
            }
            customValues {
                columnIndex
                value
            }
        }
    }
`;
export type CreateNewGeneratedDocumentMutationFn = Apollo.MutationFunction<
    CreateNewGeneratedDocumentMutation,
    CreateNewGeneratedDocumentMutationVariables
>;

/**
 * __useCreateNewGeneratedDocumentMutation__
 *
 * To run a mutation, you first call `useCreateNewGeneratedDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNewGeneratedDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNewGeneratedDocumentMutation, { data, loading, error }] = useCreateNewGeneratedDocumentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateNewGeneratedDocumentMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreateNewGeneratedDocumentMutation,
        CreateNewGeneratedDocumentMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        CreateNewGeneratedDocumentMutation,
        CreateNewGeneratedDocumentMutationVariables
    >(CreateNewGeneratedDocumentDocument, options);
}
export type CreateNewGeneratedDocumentMutationHookResult = ReturnType<
    typeof useCreateNewGeneratedDocumentMutation
>;
export type CreateNewGeneratedDocumentMutationResult =
    Apollo.MutationResult<CreateNewGeneratedDocumentMutation>;
export type CreateNewGeneratedDocumentMutationOptions =
    Apollo.BaseMutationOptions<
        CreateNewGeneratedDocumentMutation,
        CreateNewGeneratedDocumentMutationVariables
    >;
export const CreateNewIntegrationRecordDocument = gql`
    mutation createNewIntegrationRecord($input: CreateIntegrationRecordDto!) {
        newIntegration(input: $input) {
            id
            name
            integrationType
            organizationId
            details {
                ... on NetsuiteIntegrationDetails {
                    accountId
                    clientId
                    clientSecret
                    companyUrl
                }
                ... on OtherIntegrationDetails {
                    endPointUri
                    token
                }
            }
        }
    }
`;
export type CreateNewIntegrationRecordMutationFn = Apollo.MutationFunction<
    CreateNewIntegrationRecordMutation,
    CreateNewIntegrationRecordMutationVariables
>;

/**
 * __useCreateNewIntegrationRecordMutation__
 *
 * To run a mutation, you first call `useCreateNewIntegrationRecordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNewIntegrationRecordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNewIntegrationRecordMutation, { data, loading, error }] = useCreateNewIntegrationRecordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateNewIntegrationRecordMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreateNewIntegrationRecordMutation,
        CreateNewIntegrationRecordMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        CreateNewIntegrationRecordMutation,
        CreateNewIntegrationRecordMutationVariables
    >(CreateNewIntegrationRecordDocument, options);
}
export type CreateNewIntegrationRecordMutationHookResult = ReturnType<
    typeof useCreateNewIntegrationRecordMutation
>;
export type CreateNewIntegrationRecordMutationResult =
    Apollo.MutationResult<CreateNewIntegrationRecordMutation>;
export type CreateNewIntegrationRecordMutationOptions =
    Apollo.BaseMutationOptions<
        CreateNewIntegrationRecordMutation,
        CreateNewIntegrationRecordMutationVariables
    >;
export const CreateNewOrganizationDocument = gql`
    mutation createNewOrganization($input: CreateOrganizationDto!) {
        newOrganization(input: $input) {
            id
            name
            contactEmail
            owner
            avatarUrl
            contactPhone
            address {
                id
                street
                streetNumber
                unit
                city
                state
                postalCode
                country
                createdAt
                updatedAt
            }
            createdBy {
                id
            }
            deletedAt
            deletedBy {
                id
            }
            updatedBy {
                id
            }
        }
    }
`;
export type CreateNewOrganizationMutationFn = Apollo.MutationFunction<
    CreateNewOrganizationMutation,
    CreateNewOrganizationMutationVariables
>;

/**
 * __useCreateNewOrganizationMutation__
 *
 * To run a mutation, you first call `useCreateNewOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNewOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNewOrganizationMutation, { data, loading, error }] = useCreateNewOrganizationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateNewOrganizationMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreateNewOrganizationMutation,
        CreateNewOrganizationMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        CreateNewOrganizationMutation,
        CreateNewOrganizationMutationVariables
    >(CreateNewOrganizationDocument, options);
}
export type CreateNewOrganizationMutationHookResult = ReturnType<
    typeof useCreateNewOrganizationMutation
>;
export type CreateNewOrganizationMutationResult =
    Apollo.MutationResult<CreateNewOrganizationMutation>;
export type CreateNewOrganizationMutationOptions = Apollo.BaseMutationOptions<
    CreateNewOrganizationMutation,
    CreateNewOrganizationMutationVariables
>;
export const CreateNewProductDocument = gql`
    mutation createNewProduct(
        $remoteId: String
        $code: String!
        $description: String!
        $servingUnit: ServingUnit!
        $primaryUnitType: PrimaryUnitType!
        $retailCount: Float!
        $grossWeightBase: Float!
        $holePunchTearNotch: HolePunchTearNotchType!
        $holePunchTearNotchDetails: String!
        $gtin14: String!
        $upc12: String!
        $former: Float!
        $overlap: Float!
        $seal: SealType!
        $cutoff: Float!
        $weightUnit: UnitOfMeasureDto!
        $lengthUnit: UnitOfMeasureDto!
        $areaUnit: UnitOfMeasureDto!
        $prices: [ProductPriceDto!]!
        $items: ItemDto!
        $caseInfo: CaseInfoDto!
        $palletInfo: PalletInfoDto!
        $displayInfo: DisplayInfoDto!
        $shipperInfo: ShipperInfoDto!
        $truckloadInfo: TruckloadInfoDto!
    ) {
        newProduct(
            input: {
                remoteId: $remoteId
                code: $code
                description: $description
                servingUnit: $servingUnit
                primaryUnitType: $primaryUnitType
                retailCount: $retailCount
                grossWeightBase: $grossWeightBase
                holePunchTearNotch: $holePunchTearNotch
                holePunchTearNotchDetails: $holePunchTearNotchDetails
                gtin14: $gtin14
                upc12: $upc12
                former: $former
                overlap: $overlap
                seal: $seal
                cutoff: $cutoff
                weightUnit: $weightUnit
                lengthUnit: $lengthUnit
                areaUnit: $areaUnit
                prices: $prices
                items: $items
                caseInfo: $caseInfo
                palletInfo: $palletInfo
                displayInfo: $displayInfo
                shipperInfo: $shipperInfo
                truckloadInfo: $truckloadInfo
            }
        ) {
            id
            code
            description
        }
    }
`;
export type CreateNewProductMutationFn = Apollo.MutationFunction<
    CreateNewProductMutation,
    CreateNewProductMutationVariables
>;

/**
 * __useCreateNewProductMutation__
 *
 * To run a mutation, you first call `useCreateNewProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNewProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNewProductMutation, { data, loading, error }] = useCreateNewProductMutation({
 *   variables: {
 *      remoteId: // value for 'remoteId'
 *      code: // value for 'code'
 *      description: // value for 'description'
 *      servingUnit: // value for 'servingUnit'
 *      primaryUnitType: // value for 'primaryUnitType'
 *      retailCount: // value for 'retailCount'
 *      grossWeightBase: // value for 'grossWeightBase'
 *      holePunchTearNotch: // value for 'holePunchTearNotch'
 *      holePunchTearNotchDetails: // value for 'holePunchTearNotchDetails'
 *      gtin14: // value for 'gtin14'
 *      upc12: // value for 'upc12'
 *      former: // value for 'former'
 *      overlap: // value for 'overlap'
 *      seal: // value for 'seal'
 *      cutoff: // value for 'cutoff'
 *      weightUnit: // value for 'weightUnit'
 *      lengthUnit: // value for 'lengthUnit'
 *      areaUnit: // value for 'areaUnit'
 *      prices: // value for 'prices'
 *      items: // value for 'items'
 *      caseInfo: // value for 'caseInfo'
 *      palletInfo: // value for 'palletInfo'
 *      displayInfo: // value for 'displayInfo'
 *      shipperInfo: // value for 'shipperInfo'
 *      truckloadInfo: // value for 'truckloadInfo'
 *   },
 * });
 */
export function useCreateNewProductMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreateNewProductMutation,
        CreateNewProductMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        CreateNewProductMutation,
        CreateNewProductMutationVariables
    >(CreateNewProductDocument, options);
}
export type CreateNewProductMutationHookResult = ReturnType<
    typeof useCreateNewProductMutation
>;
export type CreateNewProductMutationResult =
    Apollo.MutationResult<CreateNewProductMutation>;
export type CreateNewProductMutationOptions = Apollo.BaseMutationOptions<
    CreateNewProductMutation,
    CreateNewProductMutationVariables
>;
export const CreateNewUserDocument = gql`
    mutation createNewUser($input: CreateUserDto!) {
        newUser(input: $input) {
            id
            email
            firstName
            lastName
            organization
            phone
            roles
            createdBy {
                id
            }
            deletedAt
            updatedBy {
                id
            }
        }
    }
`;
export type CreateNewUserMutationFn = Apollo.MutationFunction<
    CreateNewUserMutation,
    CreateNewUserMutationVariables
>;

/**
 * __useCreateNewUserMutation__
 *
 * To run a mutation, you first call `useCreateNewUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNewUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNewUserMutation, { data, loading, error }] = useCreateNewUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateNewUserMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreateNewUserMutation,
        CreateNewUserMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        CreateNewUserMutation,
        CreateNewUserMutationVariables
    >(CreateNewUserDocument, options);
}
export type CreateNewUserMutationHookResult = ReturnType<
    typeof useCreateNewUserMutation
>;
export type CreateNewUserMutationResult =
    Apollo.MutationResult<CreateNewUserMutation>;
export type CreateNewUserMutationOptions = Apollo.BaseMutationOptions<
    CreateNewUserMutation,
    CreateNewUserMutationVariables
>;
export const DeleteCustomerDocument = gql`
    mutation deleteCustomer($id: String!) {
        deleteCustomer(id: $id) {
            success
        }
    }
`;
export type DeleteCustomerMutationFn = Apollo.MutationFunction<
    DeleteCustomerMutation,
    DeleteCustomerMutationVariables
>;

/**
 * __useDeleteCustomerMutation__
 *
 * To run a mutation, you first call `useDeleteCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCustomerMutation, { data, loading, error }] = useDeleteCustomerMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCustomerMutation(
    baseOptions?: Apollo.MutationHookOptions<
        DeleteCustomerMutation,
        DeleteCustomerMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        DeleteCustomerMutation,
        DeleteCustomerMutationVariables
    >(DeleteCustomerDocument, options);
}
export type DeleteCustomerMutationHookResult = ReturnType<
    typeof useDeleteCustomerMutation
>;
export type DeleteCustomerMutationResult =
    Apollo.MutationResult<DeleteCustomerMutation>;
export type DeleteCustomerMutationOptions = Apollo.BaseMutationOptions<
    DeleteCustomerMutation,
    DeleteCustomerMutationVariables
>;
export const DeleteDocumentDocument = gql`
    mutation deleteDocument($id: String!) {
        deleteDocument(id: $id) {
            success
        }
    }
`;
export type DeleteDocumentMutationFn = Apollo.MutationFunction<
    DeleteDocumentMutation,
    DeleteDocumentMutationVariables
>;

/**
 * __useDeleteDocumentMutation__
 *
 * To run a mutation, you first call `useDeleteDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDocumentMutation, { data, loading, error }] = useDeleteDocumentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteDocumentMutation(
    baseOptions?: Apollo.MutationHookOptions<
        DeleteDocumentMutation,
        DeleteDocumentMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        DeleteDocumentMutation,
        DeleteDocumentMutationVariables
    >(DeleteDocumentDocument, options);
}
export type DeleteDocumentMutationHookResult = ReturnType<
    typeof useDeleteDocumentMutation
>;
export type DeleteDocumentMutationResult =
    Apollo.MutationResult<DeleteDocumentMutation>;
export type DeleteDocumentMutationOptions = Apollo.BaseMutationOptions<
    DeleteDocumentMutation,
    DeleteDocumentMutationVariables
>;
export const DeleteGeneratedDocumentDocument = gql`
    mutation deleteGeneratedDocument($id: String!) {
        deleteGeneratedDocument(id: $id) {
            success
        }
    }
`;
export type DeleteGeneratedDocumentMutationFn = Apollo.MutationFunction<
    DeleteGeneratedDocumentMutation,
    DeleteGeneratedDocumentMutationVariables
>;

/**
 * __useDeleteGeneratedDocumentMutation__
 *
 * To run a mutation, you first call `useDeleteGeneratedDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteGeneratedDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteGeneratedDocumentMutation, { data, loading, error }] = useDeleteGeneratedDocumentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteGeneratedDocumentMutation(
    baseOptions?: Apollo.MutationHookOptions<
        DeleteGeneratedDocumentMutation,
        DeleteGeneratedDocumentMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        DeleteGeneratedDocumentMutation,
        DeleteGeneratedDocumentMutationVariables
    >(DeleteGeneratedDocumentDocument, options);
}
export type DeleteGeneratedDocumentMutationHookResult = ReturnType<
    typeof useDeleteGeneratedDocumentMutation
>;
export type DeleteGeneratedDocumentMutationResult =
    Apollo.MutationResult<DeleteGeneratedDocumentMutation>;
export type DeleteGeneratedDocumentMutationOptions = Apollo.BaseMutationOptions<
    DeleteGeneratedDocumentMutation,
    DeleteGeneratedDocumentMutationVariables
>;
export const DeleteIntegrationRecordDocument = gql`
    mutation deleteIntegrationRecord($id: String!) {
        deleteIntegration(id: $id) {
            success
        }
    }
`;
export type DeleteIntegrationRecordMutationFn = Apollo.MutationFunction<
    DeleteIntegrationRecordMutation,
    DeleteIntegrationRecordMutationVariables
>;

/**
 * __useDeleteIntegrationRecordMutation__
 *
 * To run a mutation, you first call `useDeleteIntegrationRecordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteIntegrationRecordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteIntegrationRecordMutation, { data, loading, error }] = useDeleteIntegrationRecordMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteIntegrationRecordMutation(
    baseOptions?: Apollo.MutationHookOptions<
        DeleteIntegrationRecordMutation,
        DeleteIntegrationRecordMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        DeleteIntegrationRecordMutation,
        DeleteIntegrationRecordMutationVariables
    >(DeleteIntegrationRecordDocument, options);
}
export type DeleteIntegrationRecordMutationHookResult = ReturnType<
    typeof useDeleteIntegrationRecordMutation
>;
export type DeleteIntegrationRecordMutationResult =
    Apollo.MutationResult<DeleteIntegrationRecordMutation>;
export type DeleteIntegrationRecordMutationOptions = Apollo.BaseMutationOptions<
    DeleteIntegrationRecordMutation,
    DeleteIntegrationRecordMutationVariables
>;
export const DeleteOrganizationDocument = gql`
    mutation deleteOrganization($id: String!) {
        deleteOrganization(id: $id) {
            success
        }
    }
`;
export type DeleteOrganizationMutationFn = Apollo.MutationFunction<
    DeleteOrganizationMutation,
    DeleteOrganizationMutationVariables
>;

/**
 * __useDeleteOrganizationMutation__
 *
 * To run a mutation, you first call `useDeleteOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOrganizationMutation, { data, loading, error }] = useDeleteOrganizationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteOrganizationMutation(
    baseOptions?: Apollo.MutationHookOptions<
        DeleteOrganizationMutation,
        DeleteOrganizationMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        DeleteOrganizationMutation,
        DeleteOrganizationMutationVariables
    >(DeleteOrganizationDocument, options);
}
export type DeleteOrganizationMutationHookResult = ReturnType<
    typeof useDeleteOrganizationMutation
>;
export type DeleteOrganizationMutationResult =
    Apollo.MutationResult<DeleteOrganizationMutation>;
export type DeleteOrganizationMutationOptions = Apollo.BaseMutationOptions<
    DeleteOrganizationMutation,
    DeleteOrganizationMutationVariables
>;
export const DeleteProductDocument = gql`
    mutation deleteProduct($id: String!) {
        deleteProduct(id: $id) {
            success
        }
    }
`;
export type DeleteProductMutationFn = Apollo.MutationFunction<
    DeleteProductMutation,
    DeleteProductMutationVariables
>;

/**
 * __useDeleteProductMutation__
 *
 * To run a mutation, you first call `useDeleteProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProductMutation, { data, loading, error }] = useDeleteProductMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteProductMutation(
    baseOptions?: Apollo.MutationHookOptions<
        DeleteProductMutation,
        DeleteProductMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        DeleteProductMutation,
        DeleteProductMutationVariables
    >(DeleteProductDocument, options);
}
export type DeleteProductMutationHookResult = ReturnType<
    typeof useDeleteProductMutation
>;
export type DeleteProductMutationResult =
    Apollo.MutationResult<DeleteProductMutation>;
export type DeleteProductMutationOptions = Apollo.BaseMutationOptions<
    DeleteProductMutation,
    DeleteProductMutationVariables
>;
export const DeleteUserDocument = gql`
    mutation deleteUser($id: String!) {
        deleteUser(id: $id) {
            success
        }
    }
`;
export type DeleteUserMutationFn = Apollo.MutationFunction<
    DeleteUserMutation,
    DeleteUserMutationVariables
>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUserMutation(
    baseOptions?: Apollo.MutationHookOptions<
        DeleteUserMutation,
        DeleteUserMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(
        DeleteUserDocument,
        options
    );
}
export type DeleteUserMutationHookResult = ReturnType<
    typeof useDeleteUserMutation
>;
export type DeleteUserMutationResult =
    Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<
    DeleteUserMutation,
    DeleteUserMutationVariables
>;
export const UpdateExistingCustomerDocument = gql`
    mutation updateExistingCustomer($id: String!, $input: UpdateCustomerDto!) {
        updateCustomer(id: $id, input: $input) {
            id
            remoteId
            companyName
            subsidiary
            categoryChannel
            customerServiceRep
            terms
            email
            phone
            fax
            shippingMethod
            priceLevel
            creditLimit
            primaryCurrency
            ediCapable
        }
    }
`;
export type UpdateExistingCustomerMutationFn = Apollo.MutationFunction<
    UpdateExistingCustomerMutation,
    UpdateExistingCustomerMutationVariables
>;

/**
 * __useUpdateExistingCustomerMutation__
 *
 * To run a mutation, you first call `useUpdateExistingCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateExistingCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateExistingCustomerMutation, { data, loading, error }] = useUpdateExistingCustomerMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateExistingCustomerMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateExistingCustomerMutation,
        UpdateExistingCustomerMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        UpdateExistingCustomerMutation,
        UpdateExistingCustomerMutationVariables
    >(UpdateExistingCustomerDocument, options);
}
export type UpdateExistingCustomerMutationHookResult = ReturnType<
    typeof useUpdateExistingCustomerMutation
>;
export type UpdateExistingCustomerMutationResult =
    Apollo.MutationResult<UpdateExistingCustomerMutation>;
export type UpdateExistingCustomerMutationOptions = Apollo.BaseMutationOptions<
    UpdateExistingCustomerMutation,
    UpdateExistingCustomerMutationVariables
>;
export const UpdateExistingDocumentDocument = gql`
    mutation updateExistingDocument($id: String!, $input: UpdateDocumentDto!) {
        updateDocument(id: $id, input: $input) {
            id
            organization {
                ... on Organization {
                    id
                    name
                }
            }
            customer {
                ... on Customer {
                    id
                    companyName
                }
            }
            downloadUrl
            name
            deletedOn
            uploadedBy
            documentStatus
            sheets {
                index
                name
                documentType
                documentStatus
                headerRow
                columns {
                    index
                    name
                    mapping {
                        connectAIColumn
                        extraVerification
                    }
                }
            }
        }
    }
`;
export type UpdateExistingDocumentMutationFn = Apollo.MutationFunction<
    UpdateExistingDocumentMutation,
    UpdateExistingDocumentMutationVariables
>;

/**
 * __useUpdateExistingDocumentMutation__
 *
 * To run a mutation, you first call `useUpdateExistingDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateExistingDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateExistingDocumentMutation, { data, loading, error }] = useUpdateExistingDocumentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateExistingDocumentMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateExistingDocumentMutation,
        UpdateExistingDocumentMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        UpdateExistingDocumentMutation,
        UpdateExistingDocumentMutationVariables
    >(UpdateExistingDocumentDocument, options);
}
export type UpdateExistingDocumentMutationHookResult = ReturnType<
    typeof useUpdateExistingDocumentMutation
>;
export type UpdateExistingDocumentMutationResult =
    Apollo.MutationResult<UpdateExistingDocumentMutation>;
export type UpdateExistingDocumentMutationOptions = Apollo.BaseMutationOptions<
    UpdateExistingDocumentMutation,
    UpdateExistingDocumentMutationVariables
>;
export const UpdateExistingGeneratedDocumentDocument = gql`
    mutation updateExistingGeneratedDocument(
        $id: String!
        $input: UpdateGeneratedDocumentDto!
    ) {
        updateGeneratedDocument(id: $id, input: $input) {
            id
            organization {
                id
            }
            customer {
                id
            }
            document {
                id
            }
            product {
                id
                code
                description
                servingUnit
                primaryUnitType
                retailCount
                grossWeightBase
                weightUnit {
                    id
                    type
                    unit
                }
                lengthUnit {
                    id
                    type
                    unit
                }
                areaUnit {
                    id
                    type
                    unit
                }
                items {
                    id
                    flavor
                    guaranteedShelfLife
                    shelfLifeDays
                    lengthLeftToRight
                    widthFrontToBack
                    heightTopToBottom
                    netWeight
                    sellableWeight
                    filmWebWidth
                    weightUnit {
                        id
                        type
                        unit
                    }
                    lengthUnit {
                        id
                        type
                        unit
                    }
                }
                caseInfo {
                    id
                    casePack
                    masterCaseCube
                    weightUnit {
                        id
                        type
                        unit
                    }
                    lengthUnit {
                        id
                        type
                        unit
                    }
                    areaUnit {
                        id
                        type
                        unit
                    }
                    masterCaseLengthLeftToRight
                    masterCaseWidthFrontToBack
                    masterCaseHeightTopToBottom
                    masterCaseNetWt
                }
                palletInfo {
                    id
                    perTruckload
                    perContainer
                    ti
                    hi
                    casesOrUnitsPer
                    grossWeightWoPallet
                    grossWeight
                    heightTopToBottomWoPallet
                    netWeight
                    heightTopToBottom
                    widthFrontToBack
                    lengthLeftToRight
                    weightUnit {
                        id
                        type
                        unit
                    }
                    lengthUnit {
                        id
                        type
                        unit
                    }
                    areaUnit {
                        id
                        type
                        unit
                    }
                    cube
                    cubeCFWoPallet
                }
                displayInfo {
                    id
                    lengthLeftToRight
                    widthFrontToBack
                    heightTopToBottom
                    netWeight
                    weightUnit {
                        id
                        type
                        unit
                    }
                    lengthUnit {
                        id
                        type
                        unit
                    }
                }
                shipperInfo {
                    id
                    lengthLeftToRight
                    widthFrontToBack
                    heightTopToBottom
                    netWeight
                    cube
                    weightUnit {
                        id
                        type
                        unit
                    }
                    lengthUnit {
                        id
                        type
                        unit
                    }
                    areaUnit {
                        id
                        type
                        unit
                    }
                }
                truckloadInfo {
                    id
                    grossWeighttPer
                    unitsPer
                    stackLevel
                    weightUnit {
                        id
                        type
                        unit
                    }
                }
            }
            customValues {
                columnIndex
                value
            }
        }
    }
`;
export type UpdateExistingGeneratedDocumentMutationFn = Apollo.MutationFunction<
    UpdateExistingGeneratedDocumentMutation,
    UpdateExistingGeneratedDocumentMutationVariables
>;

/**
 * __useUpdateExistingGeneratedDocumentMutation__
 *
 * To run a mutation, you first call `useUpdateExistingGeneratedDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateExistingGeneratedDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateExistingGeneratedDocumentMutation, { data, loading, error }] = useUpdateExistingGeneratedDocumentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateExistingGeneratedDocumentMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateExistingGeneratedDocumentMutation,
        UpdateExistingGeneratedDocumentMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        UpdateExistingGeneratedDocumentMutation,
        UpdateExistingGeneratedDocumentMutationVariables
    >(UpdateExistingGeneratedDocumentDocument, options);
}
export type UpdateExistingGeneratedDocumentMutationHookResult = ReturnType<
    typeof useUpdateExistingGeneratedDocumentMutation
>;
export type UpdateExistingGeneratedDocumentMutationResult =
    Apollo.MutationResult<UpdateExistingGeneratedDocumentMutation>;
export type UpdateExistingGeneratedDocumentMutationOptions =
    Apollo.BaseMutationOptions<
        UpdateExistingGeneratedDocumentMutation,
        UpdateExistingGeneratedDocumentMutationVariables
    >;
export const UpdateExistingOrganizationDocument = gql`
    mutation updateExistingOrganization(
        $id: String!
        $input: UpdateOrganizationDto!
    ) {
        updateOrganization(id: $id, input: $input) {
            id
            name
            contactEmail
            owner
            avatarUrl
            contactPhone
            address {
                id
                street
                streetNumber
                unit
                city
                state
                postalCode
                country
                createdAt
                updatedAt
            }
            createdBy {
                id
            }
            deletedAt
            deletedBy {
                id
            }
            updatedBy {
                id
            }
        }
    }
`;
export type UpdateExistingOrganizationMutationFn = Apollo.MutationFunction<
    UpdateExistingOrganizationMutation,
    UpdateExistingOrganizationMutationVariables
>;

/**
 * __useUpdateExistingOrganizationMutation__
 *
 * To run a mutation, you first call `useUpdateExistingOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateExistingOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateExistingOrganizationMutation, { data, loading, error }] = useUpdateExistingOrganizationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateExistingOrganizationMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateExistingOrganizationMutation,
        UpdateExistingOrganizationMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        UpdateExistingOrganizationMutation,
        UpdateExistingOrganizationMutationVariables
    >(UpdateExistingOrganizationDocument, options);
}
export type UpdateExistingOrganizationMutationHookResult = ReturnType<
    typeof useUpdateExistingOrganizationMutation
>;
export type UpdateExistingOrganizationMutationResult =
    Apollo.MutationResult<UpdateExistingOrganizationMutation>;
export type UpdateExistingOrganizationMutationOptions =
    Apollo.BaseMutationOptions<
        UpdateExistingOrganizationMutation,
        UpdateExistingOrganizationMutationVariables
    >;
export const UpdateExistingProductDocument = gql`
    mutation updateExistingProduct(
        $id: String!
        $remoteId: String
        $code: String
        $description: String
        $servingUnit: ServingUnit
        $primaryUnitType: PrimaryUnitType
        $retailCount: Float
        $grossWeightBase: Float
        $holePunchTearNotch: HolePunchTearNotchType
        $holePunchTearNotchDetails: String
        $gtin14: String
        $upc12: String
        $former: Float
        $overlap: Float
        $seal: SealType
        $cutoff: Float
        $weightUnit: UnitOfMeasureDto
        $lengthUnit: UnitOfMeasureDto
        $areaUnit: UnitOfMeasureDto
        $prices: [ProductPriceDto!]
        $items: ItemDto
        $caseInfo: CaseInfoDto
        $palletInfo: PalletInfoDto
        $displayInfo: DisplayInfoDto
        $shipperInfo: ShipperInfoDto
        $truckloadInfo: TruckloadInfoDto
    ) {
        updateProduct(
            id: $id
            input: {
                remoteId: $remoteId
                code: $code
                description: $description
                servingUnit: $servingUnit
                primaryUnitType: $primaryUnitType
                retailCount: $retailCount
                grossWeightBase: $grossWeightBase
                holePunchTearNotch: $holePunchTearNotch
                holePunchTearNotchDetails: $holePunchTearNotchDetails
                gtin14: $gtin14
                upc12: $upc12
                former: $former
                overlap: $overlap
                seal: $seal
                cutoff: $cutoff
                weightUnit: $weightUnit
                lengthUnit: $lengthUnit
                areaUnit: $areaUnit
                prices: $prices
                items: $items
                caseInfo: $caseInfo
                palletInfo: $palletInfo
                displayInfo: $displayInfo
                shipperInfo: $shipperInfo
                truckloadInfo: $truckloadInfo
            }
        ) {
            id
            code
            description
        }
    }
`;
export type UpdateExistingProductMutationFn = Apollo.MutationFunction<
    UpdateExistingProductMutation,
    UpdateExistingProductMutationVariables
>;

/**
 * __useUpdateExistingProductMutation__
 *
 * To run a mutation, you first call `useUpdateExistingProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateExistingProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateExistingProductMutation, { data, loading, error }] = useUpdateExistingProductMutation({
 *   variables: {
 *      id: // value for 'id'
 *      remoteId: // value for 'remoteId'
 *      code: // value for 'code'
 *      description: // value for 'description'
 *      servingUnit: // value for 'servingUnit'
 *      primaryUnitType: // value for 'primaryUnitType'
 *      retailCount: // value for 'retailCount'
 *      grossWeightBase: // value for 'grossWeightBase'
 *      holePunchTearNotch: // value for 'holePunchTearNotch'
 *      holePunchTearNotchDetails: // value for 'holePunchTearNotchDetails'
 *      gtin14: // value for 'gtin14'
 *      upc12: // value for 'upc12'
 *      former: // value for 'former'
 *      overlap: // value for 'overlap'
 *      seal: // value for 'seal'
 *      cutoff: // value for 'cutoff'
 *      weightUnit: // value for 'weightUnit'
 *      lengthUnit: // value for 'lengthUnit'
 *      areaUnit: // value for 'areaUnit'
 *      prices: // value for 'prices'
 *      items: // value for 'items'
 *      caseInfo: // value for 'caseInfo'
 *      palletInfo: // value for 'palletInfo'
 *      displayInfo: // value for 'displayInfo'
 *      shipperInfo: // value for 'shipperInfo'
 *      truckloadInfo: // value for 'truckloadInfo'
 *   },
 * });
 */
export function useUpdateExistingProductMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateExistingProductMutation,
        UpdateExistingProductMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        UpdateExistingProductMutation,
        UpdateExistingProductMutationVariables
    >(UpdateExistingProductDocument, options);
}
export type UpdateExistingProductMutationHookResult = ReturnType<
    typeof useUpdateExistingProductMutation
>;
export type UpdateExistingProductMutationResult =
    Apollo.MutationResult<UpdateExistingProductMutation>;
export type UpdateExistingProductMutationOptions = Apollo.BaseMutationOptions<
    UpdateExistingProductMutation,
    UpdateExistingProductMutationVariables
>;
export const UpdateExistingUserDocument = gql`
    mutation updateExistingUser($id: String!, $input: UpdateUserDto!) {
        updateUser(id: $id, input: $input) {
            id
            email
            firstName
            lastName
            organization
            phone
            roles
            createdBy {
                id
            }
            deletedAt
            updatedBy {
                id
            }
        }
    }
`;
export type UpdateExistingUserMutationFn = Apollo.MutationFunction<
    UpdateExistingUserMutation,
    UpdateExistingUserMutationVariables
>;

/**
 * __useUpdateExistingUserMutation__
 *
 * To run a mutation, you first call `useUpdateExistingUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateExistingUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateExistingUserMutation, { data, loading, error }] = useUpdateExistingUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateExistingUserMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateExistingUserMutation,
        UpdateExistingUserMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        UpdateExistingUserMutation,
        UpdateExistingUserMutationVariables
    >(UpdateExistingUserDocument, options);
}
export type UpdateExistingUserMutationHookResult = ReturnType<
    typeof useUpdateExistingUserMutation
>;
export type UpdateExistingUserMutationResult =
    Apollo.MutationResult<UpdateExistingUserMutation>;
export type UpdateExistingUserMutationOptions = Apollo.BaseMutationOptions<
    UpdateExistingUserMutation,
    UpdateExistingUserMutationVariables
>;
export const UpdateExistingIntegrationRecordDocument = gql`
    mutation updateExistingIntegrationRecord(
        $id: String!
        $input: UpdateIntegrationRecordDto!
    ) {
        updateIntegration(id: $id, input: $input) {
            id
            name
            integrationType
            organizationId
            details {
                ... on NetsuiteIntegrationDetails {
                    accountId
                    clientId
                    clientSecret
                    companyUrl
                }
                ... on OtherIntegrationDetails {
                    endPointUri
                    token
                }
            }
        }
    }
`;
export type UpdateExistingIntegrationRecordMutationFn = Apollo.MutationFunction<
    UpdateExistingIntegrationRecordMutation,
    UpdateExistingIntegrationRecordMutationVariables
>;

/**
 * __useUpdateExistingIntegrationRecordMutation__
 *
 * To run a mutation, you first call `useUpdateExistingIntegrationRecordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateExistingIntegrationRecordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateExistingIntegrationRecordMutation, { data, loading, error }] = useUpdateExistingIntegrationRecordMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateExistingIntegrationRecordMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateExistingIntegrationRecordMutation,
        UpdateExistingIntegrationRecordMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        UpdateExistingIntegrationRecordMutation,
        UpdateExistingIntegrationRecordMutationVariables
    >(UpdateExistingIntegrationRecordDocument, options);
}
export type UpdateExistingIntegrationRecordMutationHookResult = ReturnType<
    typeof useUpdateExistingIntegrationRecordMutation
>;
export type UpdateExistingIntegrationRecordMutationResult =
    Apollo.MutationResult<UpdateExistingIntegrationRecordMutation>;
export type UpdateExistingIntegrationRecordMutationOptions =
    Apollo.BaseMutationOptions<
        UpdateExistingIntegrationRecordMutation,
        UpdateExistingIntegrationRecordMutationVariables
    >;
export const CheckDocument = gql`
    query check {
        check {
            info {
                organizations
                users
                integrationRecords
                products
            }
        }
    }
`;

/**
 * __useCheckQuery__
 *
 * To run a query within a React component, call `useCheckQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckQuery({
 *   variables: {
 *   },
 * });
 */
export function useCheckQuery(
    baseOptions?: Apollo.QueryHookOptions<CheckQuery, CheckQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<CheckQuery, CheckQueryVariables>(
        CheckDocument,
        options
    );
}
export function useCheckLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<CheckQuery, CheckQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<CheckQuery, CheckQueryVariables>(
        CheckDocument,
        options
    );
}
export function useCheckSuspenseQuery(
    baseOptions?: Apollo.SuspenseQueryHookOptions<
        CheckQuery,
        CheckQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<CheckQuery, CheckQueryVariables>(
        CheckDocument,
        options
    );
}
export type CheckQueryHookResult = ReturnType<typeof useCheckQuery>;
export type CheckLazyQueryHookResult = ReturnType<typeof useCheckLazyQuery>;
export type CheckSuspenseQueryHookResult = ReturnType<
    typeof useCheckSuspenseQuery
>;
export type CheckQueryResult = Apollo.QueryResult<
    CheckQuery,
    CheckQueryVariables
>;
export const GetCurrentUserDocument = gql`
    query GetCurrentUser {
        me {
            id
            firstName
            lastName
            email
            phone
            organization
            roles
        }
    }
`;

/**
 * __useGetCurrentUserQuery__
 *
 * To run a query within a React component, call `useGetCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentUserQuery(
    baseOptions?: Apollo.QueryHookOptions<
        GetCurrentUserQuery,
        GetCurrentUserQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(
        GetCurrentUserDocument,
        options
    );
}
export function useGetCurrentUserLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetCurrentUserQuery,
        GetCurrentUserQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        GetCurrentUserQuery,
        GetCurrentUserQueryVariables
    >(GetCurrentUserDocument, options);
}
export function useGetCurrentUserSuspenseQuery(
    baseOptions?: Apollo.SuspenseQueryHookOptions<
        GetCurrentUserQuery,
        GetCurrentUserQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        GetCurrentUserQuery,
        GetCurrentUserQueryVariables
    >(GetCurrentUserDocument, options);
}
export type GetCurrentUserQueryHookResult = ReturnType<
    typeof useGetCurrentUserQuery
>;
export type GetCurrentUserLazyQueryHookResult = ReturnType<
    typeof useGetCurrentUserLazyQuery
>;
export type GetCurrentUserSuspenseQueryHookResult = ReturnType<
    typeof useGetCurrentUserSuspenseQuery
>;
export type GetCurrentUserQueryResult = Apollo.QueryResult<
    GetCurrentUserQuery,
    GetCurrentUserQueryVariables
>;
export const GetCustomerByIdDocument = gql`
    query getCustomerById($id: String!) {
        customer(id: $id) {
            id
            remoteId
            companyName
            subsidiary
            categoryChannel
            customerServiceRep
            terms
            email
            phone
            fax
            shippingMethod
            priceLevel
            creditLimit
            primaryCurrency
            ediCapable
        }
    }
`;

/**
 * __useGetCustomerByIdQuery__
 *
 * To run a query within a React component, call `useGetCustomerByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCustomerByIdQuery(
    baseOptions: Apollo.QueryHookOptions<
        GetCustomerByIdQuery,
        GetCustomerByIdQueryVariables
    > &
        (
            | { variables: GetCustomerByIdQueryVariables; skip?: boolean }
            | { skip: boolean }
        )
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetCustomerByIdQuery, GetCustomerByIdQueryVariables>(
        GetCustomerByIdDocument,
        options
    );
}
export function useGetCustomerByIdLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetCustomerByIdQuery,
        GetCustomerByIdQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        GetCustomerByIdQuery,
        GetCustomerByIdQueryVariables
    >(GetCustomerByIdDocument, options);
}
export function useGetCustomerByIdSuspenseQuery(
    baseOptions?: Apollo.SuspenseQueryHookOptions<
        GetCustomerByIdQuery,
        GetCustomerByIdQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        GetCustomerByIdQuery,
        GetCustomerByIdQueryVariables
    >(GetCustomerByIdDocument, options);
}
export type GetCustomerByIdQueryHookResult = ReturnType<
    typeof useGetCustomerByIdQuery
>;
export type GetCustomerByIdLazyQueryHookResult = ReturnType<
    typeof useGetCustomerByIdLazyQuery
>;
export type GetCustomerByIdSuspenseQueryHookResult = ReturnType<
    typeof useGetCustomerByIdSuspenseQuery
>;
export type GetCustomerByIdQueryResult = Apollo.QueryResult<
    GetCustomerByIdQuery,
    GetCustomerByIdQueryVariables
>;
export const GetAllCustomersDocument = gql`
    query getAllCustomers {
        customers {
            id
            remoteId
            companyName
            subsidiary
            categoryChannel
            customerServiceRep
            terms
            email
            phone
            fax
            shippingMethod
            priceLevel
            creditLimit
            primaryCurrency
            ediCapable
        }
    }
`;

/**
 * __useGetAllCustomersQuery__
 *
 * To run a query within a React component, call `useGetAllCustomersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllCustomersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllCustomersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllCustomersQuery(
    baseOptions?: Apollo.QueryHookOptions<
        GetAllCustomersQuery,
        GetAllCustomersQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetAllCustomersQuery, GetAllCustomersQueryVariables>(
        GetAllCustomersDocument,
        options
    );
}
export function useGetAllCustomersLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetAllCustomersQuery,
        GetAllCustomersQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        GetAllCustomersQuery,
        GetAllCustomersQueryVariables
    >(GetAllCustomersDocument, options);
}
export function useGetAllCustomersSuspenseQuery(
    baseOptions?: Apollo.SuspenseQueryHookOptions<
        GetAllCustomersQuery,
        GetAllCustomersQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        GetAllCustomersQuery,
        GetAllCustomersQueryVariables
    >(GetAllCustomersDocument, options);
}
export type GetAllCustomersQueryHookResult = ReturnType<
    typeof useGetAllCustomersQuery
>;
export type GetAllCustomersLazyQueryHookResult = ReturnType<
    typeof useGetAllCustomersLazyQuery
>;
export type GetAllCustomersSuspenseQueryHookResult = ReturnType<
    typeof useGetAllCustomersSuspenseQuery
>;
export type GetAllCustomersQueryResult = Apollo.QueryResult<
    GetAllCustomersQuery,
    GetAllCustomersQueryVariables
>;
export const GetDocumentByIdDocument = gql`
    query getDocumentById($id: String!) {
        document(id: $id) {
            id
            organization {
                ... on Organization {
                    id
                    name
                }
            }
            customer {
                ... on Customer {
                    id
                    companyName
                }
            }
            downloadUrl
            name
            deletedOn
            uploadedBy
            documentStatus
            sheets {
                index
                name
                documentType
                documentStatus
                headerRow
                columns {
                    index
                    name
                    mapping {
                        connectAIColumn
                        extraVerification
                    }
                }
            }
        }
    }
`;

/**
 * __useGetDocumentByIdQuery__
 *
 * To run a query within a React component, call `useGetDocumentByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDocumentByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDocumentByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetDocumentByIdQuery(
    baseOptions: Apollo.QueryHookOptions<
        GetDocumentByIdQuery,
        GetDocumentByIdQueryVariables
    > &
        (
            | { variables: GetDocumentByIdQueryVariables; skip?: boolean }
            | { skip: boolean }
        )
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetDocumentByIdQuery, GetDocumentByIdQueryVariables>(
        GetDocumentByIdDocument,
        options
    );
}
export function useGetDocumentByIdLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetDocumentByIdQuery,
        GetDocumentByIdQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        GetDocumentByIdQuery,
        GetDocumentByIdQueryVariables
    >(GetDocumentByIdDocument, options);
}
export function useGetDocumentByIdSuspenseQuery(
    baseOptions?: Apollo.SuspenseQueryHookOptions<
        GetDocumentByIdQuery,
        GetDocumentByIdQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        GetDocumentByIdQuery,
        GetDocumentByIdQueryVariables
    >(GetDocumentByIdDocument, options);
}
export type GetDocumentByIdQueryHookResult = ReturnType<
    typeof useGetDocumentByIdQuery
>;
export type GetDocumentByIdLazyQueryHookResult = ReturnType<
    typeof useGetDocumentByIdLazyQuery
>;
export type GetDocumentByIdSuspenseQueryHookResult = ReturnType<
    typeof useGetDocumentByIdSuspenseQuery
>;
export type GetDocumentByIdQueryResult = Apollo.QueryResult<
    GetDocumentByIdQuery,
    GetDocumentByIdQueryVariables
>;
export const GetAllDocumentsDocument = gql`
    query getAllDocuments {
        documents {
            id
            organization {
                ... on Organization {
                    id
                    name
                }
            }
            customer {
                ... on Customer {
                    id
                    companyName
                }
            }
            downloadUrl
            name
            deletedOn
            uploadedBy
            documentStatus
            sheets {
                index
                name
                documentType
                documentStatus
                headerRow
                columns {
                    index
                    name
                    mapping {
                        connectAIColumn
                        extraVerification
                    }
                }
            }
        }
    }
`;

/**
 * __useGetAllDocumentsQuery__
 *
 * To run a query within a React component, call `useGetAllDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllDocumentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllDocumentsQuery(
    baseOptions?: Apollo.QueryHookOptions<
        GetAllDocumentsQuery,
        GetAllDocumentsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetAllDocumentsQuery, GetAllDocumentsQueryVariables>(
        GetAllDocumentsDocument,
        options
    );
}
export function useGetAllDocumentsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetAllDocumentsQuery,
        GetAllDocumentsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        GetAllDocumentsQuery,
        GetAllDocumentsQueryVariables
    >(GetAllDocumentsDocument, options);
}
export function useGetAllDocumentsSuspenseQuery(
    baseOptions?: Apollo.SuspenseQueryHookOptions<
        GetAllDocumentsQuery,
        GetAllDocumentsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        GetAllDocumentsQuery,
        GetAllDocumentsQueryVariables
    >(GetAllDocumentsDocument, options);
}
export type GetAllDocumentsQueryHookResult = ReturnType<
    typeof useGetAllDocumentsQuery
>;
export type GetAllDocumentsLazyQueryHookResult = ReturnType<
    typeof useGetAllDocumentsLazyQuery
>;
export type GetAllDocumentsSuspenseQueryHookResult = ReturnType<
    typeof useGetAllDocumentsSuspenseQuery
>;
export type GetAllDocumentsQueryResult = Apollo.QueryResult<
    GetAllDocumentsQuery,
    GetAllDocumentsQueryVariables
>;
export const GetGeneratedDocumentByIdDocument = gql`
    query getGeneratedDocumentById($id: String!) {
        generatedDocument(id: $id) {
            id
            organization {
                id
            }
            customer {
                id
            }
            document {
                id
            }
            product {
                id
                code
                description
                servingUnit
                primaryUnitType
                retailCount
                grossWeightBase
                weightUnit {
                    id
                    type
                    unit
                }
                lengthUnit {
                    id
                    type
                    unit
                }
                areaUnit {
                    id
                    type
                    unit
                }
                items {
                    id
                    flavor
                    guaranteedShelfLife
                    shelfLifeDays
                    lengthLeftToRight
                    widthFrontToBack
                    heightTopToBottom
                    netWeight
                    sellableWeight
                    filmWebWidth
                    weightUnit {
                        id
                        type
                        unit
                    }
                    lengthUnit {
                        id
                        type
                        unit
                    }
                }
                caseInfo {
                    id
                    casePack
                    masterCaseCube
                    weightUnit {
                        id
                        type
                        unit
                    }
                    lengthUnit {
                        id
                        type
                        unit
                    }
                    areaUnit {
                        id
                        type
                        unit
                    }
                    masterCaseLengthLeftToRight
                    masterCaseWidthFrontToBack
                    masterCaseHeightTopToBottom
                    masterCaseNetWt
                }
                palletInfo {
                    id
                    perTruckload
                    perContainer
                    ti
                    hi
                    casesOrUnitsPer
                    grossWeightWoPallet
                    grossWeight
                    heightTopToBottomWoPallet
                    netWeight
                    heightTopToBottom
                    widthFrontToBack
                    lengthLeftToRight
                    weightUnit {
                        id
                        type
                        unit
                    }
                    lengthUnit {
                        id
                        type
                        unit
                    }
                    areaUnit {
                        id
                        type
                        unit
                    }
                    cube
                    cubeCFWoPallet
                }
                displayInfo {
                    id
                    lengthLeftToRight
                    widthFrontToBack
                    heightTopToBottom
                    netWeight
                    weightUnit {
                        id
                        type
                        unit
                    }
                    lengthUnit {
                        id
                        type
                        unit
                    }
                }
                shipperInfo {
                    id
                    lengthLeftToRight
                    widthFrontToBack
                    heightTopToBottom
                    netWeight
                    cube
                    weightUnit {
                        id
                        type
                        unit
                    }
                    lengthUnit {
                        id
                        type
                        unit
                    }
                    areaUnit {
                        id
                        type
                        unit
                    }
                }
                truckloadInfo {
                    id
                    grossWeighttPer
                    unitsPer
                    stackLevel
                    weightUnit {
                        id
                        type
                        unit
                    }
                }
            }
            customValues {
                columnIndex
                value
            }
        }
    }
`;

/**
 * __useGetGeneratedDocumentByIdQuery__
 *
 * To run a query within a React component, call `useGetGeneratedDocumentByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGeneratedDocumentByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGeneratedDocumentByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetGeneratedDocumentByIdQuery(
    baseOptions: Apollo.QueryHookOptions<
        GetGeneratedDocumentByIdQuery,
        GetGeneratedDocumentByIdQueryVariables
    > &
        (
            | {
                  variables: GetGeneratedDocumentByIdQueryVariables;
                  skip?: boolean;
              }
            | { skip: boolean }
        )
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        GetGeneratedDocumentByIdQuery,
        GetGeneratedDocumentByIdQueryVariables
    >(GetGeneratedDocumentByIdDocument, options);
}
export function useGetGeneratedDocumentByIdLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetGeneratedDocumentByIdQuery,
        GetGeneratedDocumentByIdQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        GetGeneratedDocumentByIdQuery,
        GetGeneratedDocumentByIdQueryVariables
    >(GetGeneratedDocumentByIdDocument, options);
}
export function useGetGeneratedDocumentByIdSuspenseQuery(
    baseOptions?: Apollo.SuspenseQueryHookOptions<
        GetGeneratedDocumentByIdQuery,
        GetGeneratedDocumentByIdQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        GetGeneratedDocumentByIdQuery,
        GetGeneratedDocumentByIdQueryVariables
    >(GetGeneratedDocumentByIdDocument, options);
}
export type GetGeneratedDocumentByIdQueryHookResult = ReturnType<
    typeof useGetGeneratedDocumentByIdQuery
>;
export type GetGeneratedDocumentByIdLazyQueryHookResult = ReturnType<
    typeof useGetGeneratedDocumentByIdLazyQuery
>;
export type GetGeneratedDocumentByIdSuspenseQueryHookResult = ReturnType<
    typeof useGetGeneratedDocumentByIdSuspenseQuery
>;
export type GetGeneratedDocumentByIdQueryResult = Apollo.QueryResult<
    GetGeneratedDocumentByIdQuery,
    GetGeneratedDocumentByIdQueryVariables
>;
export const GetAllGeneratedDocumentsDocument = gql`
    query getAllGeneratedDocuments {
        generatedDocuments {
            id
            organization {
                id
            }
            customer {
                id
            }
            document {
                id
            }
            product {
                id
                code
                description
                servingUnit
                primaryUnitType
                retailCount
                grossWeightBase
                weightUnit {
                    id
                    type
                    unit
                }
                lengthUnit {
                    id
                    type
                    unit
                }
                areaUnit {
                    id
                    type
                    unit
                }
                items {
                    id
                    flavor
                    guaranteedShelfLife
                    shelfLifeDays
                    lengthLeftToRight
                    widthFrontToBack
                    heightTopToBottom
                    netWeight
                    sellableWeight
                    filmWebWidth
                    weightUnit {
                        id
                        type
                        unit
                    }
                    lengthUnit {
                        id
                        type
                        unit
                    }
                }
                caseInfo {
                    id
                    casePack
                    masterCaseCube
                    weightUnit {
                        id
                        type
                        unit
                    }
                    lengthUnit {
                        id
                        type
                        unit
                    }
                    areaUnit {
                        id
                        type
                        unit
                    }
                    masterCaseLengthLeftToRight
                    masterCaseWidthFrontToBack
                    masterCaseHeightTopToBottom
                    masterCaseNetWt
                }
                palletInfo {
                    id
                    perTruckload
                    perContainer
                    ti
                    hi
                    casesOrUnitsPer
                    grossWeightWoPallet
                    grossWeight
                    heightTopToBottomWoPallet
                    netWeight
                    heightTopToBottom
                    widthFrontToBack
                    lengthLeftToRight
                    weightUnit {
                        id
                        type
                        unit
                    }
                    lengthUnit {
                        id
                        type
                        unit
                    }
                    areaUnit {
                        id
                        type
                        unit
                    }
                    cube
                    cubeCFWoPallet
                }
                displayInfo {
                    id
                    lengthLeftToRight
                    widthFrontToBack
                    heightTopToBottom
                    netWeight
                    weightUnit {
                        id
                        type
                        unit
                    }
                    lengthUnit {
                        id
                        type
                        unit
                    }
                }
                shipperInfo {
                    id
                    lengthLeftToRight
                    widthFrontToBack
                    heightTopToBottom
                    netWeight
                    cube
                    weightUnit {
                        id
                        type
                        unit
                    }
                    lengthUnit {
                        id
                        type
                        unit
                    }
                    areaUnit {
                        id
                        type
                        unit
                    }
                }
                truckloadInfo {
                    id
                    grossWeighttPer
                    unitsPer
                    stackLevel
                    weightUnit {
                        id
                        type
                        unit
                    }
                }
            }
            customValues {
                columnIndex
                value
            }
        }
    }
`;

/**
 * __useGetAllGeneratedDocumentsQuery__
 *
 * To run a query within a React component, call `useGetAllGeneratedDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllGeneratedDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllGeneratedDocumentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllGeneratedDocumentsQuery(
    baseOptions?: Apollo.QueryHookOptions<
        GetAllGeneratedDocumentsQuery,
        GetAllGeneratedDocumentsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        GetAllGeneratedDocumentsQuery,
        GetAllGeneratedDocumentsQueryVariables
    >(GetAllGeneratedDocumentsDocument, options);
}
export function useGetAllGeneratedDocumentsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetAllGeneratedDocumentsQuery,
        GetAllGeneratedDocumentsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        GetAllGeneratedDocumentsQuery,
        GetAllGeneratedDocumentsQueryVariables
    >(GetAllGeneratedDocumentsDocument, options);
}
export function useGetAllGeneratedDocumentsSuspenseQuery(
    baseOptions?: Apollo.SuspenseQueryHookOptions<
        GetAllGeneratedDocumentsQuery,
        GetAllGeneratedDocumentsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        GetAllGeneratedDocumentsQuery,
        GetAllGeneratedDocumentsQueryVariables
    >(GetAllGeneratedDocumentsDocument, options);
}
export type GetAllGeneratedDocumentsQueryHookResult = ReturnType<
    typeof useGetAllGeneratedDocumentsQuery
>;
export type GetAllGeneratedDocumentsLazyQueryHookResult = ReturnType<
    typeof useGetAllGeneratedDocumentsLazyQuery
>;
export type GetAllGeneratedDocumentsSuspenseQueryHookResult = ReturnType<
    typeof useGetAllGeneratedDocumentsSuspenseQuery
>;
export type GetAllGeneratedDocumentsQueryResult = Apollo.QueryResult<
    GetAllGeneratedDocumentsQuery,
    GetAllGeneratedDocumentsQueryVariables
>;
export const GetIntegrationRecordByIdDocument = gql`
    query getIntegrationRecordById($id: String!) {
        integration(id: $id) {
            id
            name
            integrationType
            organizationId
            details {
                ... on NetsuiteIntegrationDetails {
                    accountId
                    clientId
                    clientSecret
                    companyUrl
                }
                ... on OtherIntegrationDetails {
                    endPointUri
                    token
                }
            }
        }
    }
`;

/**
 * __useGetIntegrationRecordByIdQuery__
 *
 * To run a query within a React component, call `useGetIntegrationRecordByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIntegrationRecordByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIntegrationRecordByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetIntegrationRecordByIdQuery(
    baseOptions: Apollo.QueryHookOptions<
        GetIntegrationRecordByIdQuery,
        GetIntegrationRecordByIdQueryVariables
    > &
        (
            | {
                  variables: GetIntegrationRecordByIdQueryVariables;
                  skip?: boolean;
              }
            | { skip: boolean }
        )
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        GetIntegrationRecordByIdQuery,
        GetIntegrationRecordByIdQueryVariables
    >(GetIntegrationRecordByIdDocument, options);
}
export function useGetIntegrationRecordByIdLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetIntegrationRecordByIdQuery,
        GetIntegrationRecordByIdQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        GetIntegrationRecordByIdQuery,
        GetIntegrationRecordByIdQueryVariables
    >(GetIntegrationRecordByIdDocument, options);
}
export function useGetIntegrationRecordByIdSuspenseQuery(
    baseOptions?: Apollo.SuspenseQueryHookOptions<
        GetIntegrationRecordByIdQuery,
        GetIntegrationRecordByIdQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        GetIntegrationRecordByIdQuery,
        GetIntegrationRecordByIdQueryVariables
    >(GetIntegrationRecordByIdDocument, options);
}
export type GetIntegrationRecordByIdQueryHookResult = ReturnType<
    typeof useGetIntegrationRecordByIdQuery
>;
export type GetIntegrationRecordByIdLazyQueryHookResult = ReturnType<
    typeof useGetIntegrationRecordByIdLazyQuery
>;
export type GetIntegrationRecordByIdSuspenseQueryHookResult = ReturnType<
    typeof useGetIntegrationRecordByIdSuspenseQuery
>;
export type GetIntegrationRecordByIdQueryResult = Apollo.QueryResult<
    GetIntegrationRecordByIdQuery,
    GetIntegrationRecordByIdQueryVariables
>;
export const GetAllIntegrationRecordsDocument = gql`
    query getAllIntegrationRecords {
        integrations {
            id
            name
            integrationType
            organizationId
            details {
                ... on NetsuiteIntegrationDetails {
                    accountId
                    clientId
                    clientSecret
                    companyUrl
                }
                ... on OtherIntegrationDetails {
                    endPointUri
                    token
                }
            }
        }
    }
`;

/**
 * __useGetAllIntegrationRecordsQuery__
 *
 * To run a query within a React component, call `useGetAllIntegrationRecordsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllIntegrationRecordsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllIntegrationRecordsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllIntegrationRecordsQuery(
    baseOptions?: Apollo.QueryHookOptions<
        GetAllIntegrationRecordsQuery,
        GetAllIntegrationRecordsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        GetAllIntegrationRecordsQuery,
        GetAllIntegrationRecordsQueryVariables
    >(GetAllIntegrationRecordsDocument, options);
}
export function useGetAllIntegrationRecordsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetAllIntegrationRecordsQuery,
        GetAllIntegrationRecordsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        GetAllIntegrationRecordsQuery,
        GetAllIntegrationRecordsQueryVariables
    >(GetAllIntegrationRecordsDocument, options);
}
export function useGetAllIntegrationRecordsSuspenseQuery(
    baseOptions?: Apollo.SuspenseQueryHookOptions<
        GetAllIntegrationRecordsQuery,
        GetAllIntegrationRecordsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        GetAllIntegrationRecordsQuery,
        GetAllIntegrationRecordsQueryVariables
    >(GetAllIntegrationRecordsDocument, options);
}
export type GetAllIntegrationRecordsQueryHookResult = ReturnType<
    typeof useGetAllIntegrationRecordsQuery
>;
export type GetAllIntegrationRecordsLazyQueryHookResult = ReturnType<
    typeof useGetAllIntegrationRecordsLazyQuery
>;
export type GetAllIntegrationRecordsSuspenseQueryHookResult = ReturnType<
    typeof useGetAllIntegrationRecordsSuspenseQuery
>;
export type GetAllIntegrationRecordsQueryResult = Apollo.QueryResult<
    GetAllIntegrationRecordsQuery,
    GetAllIntegrationRecordsQueryVariables
>;
export const GetMapValuesDocument = gql`
    query getMapValues {
        mapValues {
            label
            value
        }
    }
`;

/**
 * __useGetMapValuesQuery__
 *
 * To run a query within a React component, call `useGetMapValuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMapValuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMapValuesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMapValuesQuery(
    baseOptions?: Apollo.QueryHookOptions<
        GetMapValuesQuery,
        GetMapValuesQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetMapValuesQuery, GetMapValuesQueryVariables>(
        GetMapValuesDocument,
        options
    );
}
export function useGetMapValuesLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetMapValuesQuery,
        GetMapValuesQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GetMapValuesQuery, GetMapValuesQueryVariables>(
        GetMapValuesDocument,
        options
    );
}
export function useGetMapValuesSuspenseQuery(
    baseOptions?: Apollo.SuspenseQueryHookOptions<
        GetMapValuesQuery,
        GetMapValuesQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        GetMapValuesQuery,
        GetMapValuesQueryVariables
    >(GetMapValuesDocument, options);
}
export type GetMapValuesQueryHookResult = ReturnType<
    typeof useGetMapValuesQuery
>;
export type GetMapValuesLazyQueryHookResult = ReturnType<
    typeof useGetMapValuesLazyQuery
>;
export type GetMapValuesSuspenseQueryHookResult = ReturnType<
    typeof useGetMapValuesSuspenseQuery
>;
export type GetMapValuesQueryResult = Apollo.QueryResult<
    GetMapValuesQuery,
    GetMapValuesQueryVariables
>;
export const GetOrganizationByIdDocument = gql`
    query getOrganizationById($id: String!) {
        Organization(id: $id) {
            id
            name
            contactEmail
            owner
            avatarUrl
            contactPhone
            address {
                id
                street
                streetNumber
                unit
                city
                state
                postalCode
                country
                createdAt
                updatedAt
            }
        }
    }
`;

/**
 * __useGetOrganizationByIdQuery__
 *
 * To run a query within a React component, call `useGetOrganizationByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOrganizationByIdQuery(
    baseOptions: Apollo.QueryHookOptions<
        GetOrganizationByIdQuery,
        GetOrganizationByIdQueryVariables
    > &
        (
            | { variables: GetOrganizationByIdQueryVariables; skip?: boolean }
            | { skip: boolean }
        )
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        GetOrganizationByIdQuery,
        GetOrganizationByIdQueryVariables
    >(GetOrganizationByIdDocument, options);
}
export function useGetOrganizationByIdLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetOrganizationByIdQuery,
        GetOrganizationByIdQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        GetOrganizationByIdQuery,
        GetOrganizationByIdQueryVariables
    >(GetOrganizationByIdDocument, options);
}
export function useGetOrganizationByIdSuspenseQuery(
    baseOptions?: Apollo.SuspenseQueryHookOptions<
        GetOrganizationByIdQuery,
        GetOrganizationByIdQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        GetOrganizationByIdQuery,
        GetOrganizationByIdQueryVariables
    >(GetOrganizationByIdDocument, options);
}
export type GetOrganizationByIdQueryHookResult = ReturnType<
    typeof useGetOrganizationByIdQuery
>;
export type GetOrganizationByIdLazyQueryHookResult = ReturnType<
    typeof useGetOrganizationByIdLazyQuery
>;
export type GetOrganizationByIdSuspenseQueryHookResult = ReturnType<
    typeof useGetOrganizationByIdSuspenseQuery
>;
export type GetOrganizationByIdQueryResult = Apollo.QueryResult<
    GetOrganizationByIdQuery,
    GetOrganizationByIdQueryVariables
>;
export const GetAllOrganizationsDocument = gql`
    query getAllOrganizations {
        Organizations {
            id
            name
            contactEmail
            avatarUrl
            contactPhone
            address {
                id
                street
                streetNumber
                unit
                city
                state
                postalCode
                country
                createdAt
                updatedAt
            }
            owner
        }
    }
`;

/**
 * __useGetAllOrganizationsQuery__
 *
 * To run a query within a React component, call `useGetAllOrganizationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllOrganizationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllOrganizationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllOrganizationsQuery(
    baseOptions?: Apollo.QueryHookOptions<
        GetAllOrganizationsQuery,
        GetAllOrganizationsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        GetAllOrganizationsQuery,
        GetAllOrganizationsQueryVariables
    >(GetAllOrganizationsDocument, options);
}
export function useGetAllOrganizationsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetAllOrganizationsQuery,
        GetAllOrganizationsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        GetAllOrganizationsQuery,
        GetAllOrganizationsQueryVariables
    >(GetAllOrganizationsDocument, options);
}
export function useGetAllOrganizationsSuspenseQuery(
    baseOptions?: Apollo.SuspenseQueryHookOptions<
        GetAllOrganizationsQuery,
        GetAllOrganizationsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        GetAllOrganizationsQuery,
        GetAllOrganizationsQueryVariables
    >(GetAllOrganizationsDocument, options);
}
export type GetAllOrganizationsQueryHookResult = ReturnType<
    typeof useGetAllOrganizationsQuery
>;
export type GetAllOrganizationsLazyQueryHookResult = ReturnType<
    typeof useGetAllOrganizationsLazyQuery
>;
export type GetAllOrganizationsSuspenseQueryHookResult = ReturnType<
    typeof useGetAllOrganizationsSuspenseQuery
>;
export type GetAllOrganizationsQueryResult = Apollo.QueryResult<
    GetAllOrganizationsQuery,
    GetAllOrganizationsQueryVariables
>;
export const GetProductByIdDocument = gql`
    query getProductById($id: String!) {
        Product(id: $id) {
            id
            remoteId
            code
            description
            servingUnit
            primaryUnitType
            retailCount
            grossWeightBase
            weightUnit {
                id
                type
                unit
            }
            lengthUnit {
                id
                type
                unit
            }
            areaUnit {
                id
                type
                unit
            }
            prices {
                id
                price
                currency
                priceLevel
                priceQuantity
            }
            items {
                id
                flavor
                guaranteedShelfLife
                shelfLifeDays
                lengthLeftToRight
                widthFrontToBack
                heightTopToBottom
                netWeight
                sellableWeight
                filmWebWidth
                weightUnit {
                    id
                    type
                    unit
                }
                lengthUnit {
                    id
                    type
                    unit
                }
            }
            caseInfo {
                id
                casePack
                masterCaseCube
                weightUnit {
                    id
                    type
                    unit
                }
                lengthUnit {
                    id
                    type
                    unit
                }
                areaUnit {
                    id
                    type
                    unit
                }
                masterCaseLengthLeftToRight
                masterCaseWidthFrontToBack
                masterCaseHeightTopToBottom
                masterCaseNetWt
            }
            palletInfo {
                id
                perTruckload
                perContainer
                ti
                hi
                casesOrUnitsPer
                grossWeightWoPallet
                grossWeight
                heightTopToBottomWoPallet
                netWeight
                heightTopToBottom
                widthFrontToBack
                lengthLeftToRight
                weightUnit {
                    id
                    type
                    unit
                }
                lengthUnit {
                    id
                    type
                    unit
                }
                areaUnit {
                    id
                    type
                    unit
                }
                cube
                cubeCFWoPallet
            }
            displayInfo {
                id
                lengthLeftToRight
                widthFrontToBack
                heightTopToBottom
                netWeight
                weightUnit {
                    id
                    type
                    unit
                }
                lengthUnit {
                    id
                    type
                    unit
                }
            }
            shipperInfo {
                id
                lengthLeftToRight
                widthFrontToBack
                heightTopToBottom
                netWeight
                cube
                weightUnit {
                    id
                    type
                    unit
                }
                lengthUnit {
                    id
                    type
                    unit
                }
                areaUnit {
                    id
                    type
                    unit
                }
            }
            truckloadInfo {
                id
                grossWeighttPer
                unitsPer
                stackLevel
                weightUnit {
                    id
                    type
                    unit
                }
            }
        }
    }
`;

/**
 * __useGetProductByIdQuery__
 *
 * To run a query within a React component, call `useGetProductByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetProductByIdQuery(
    baseOptions: Apollo.QueryHookOptions<
        GetProductByIdQuery,
        GetProductByIdQueryVariables
    > &
        (
            | { variables: GetProductByIdQueryVariables; skip?: boolean }
            | { skip: boolean }
        )
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetProductByIdQuery, GetProductByIdQueryVariables>(
        GetProductByIdDocument,
        options
    );
}
export function useGetProductByIdLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetProductByIdQuery,
        GetProductByIdQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        GetProductByIdQuery,
        GetProductByIdQueryVariables
    >(GetProductByIdDocument, options);
}
export function useGetProductByIdSuspenseQuery(
    baseOptions?: Apollo.SuspenseQueryHookOptions<
        GetProductByIdQuery,
        GetProductByIdQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        GetProductByIdQuery,
        GetProductByIdQueryVariables
    >(GetProductByIdDocument, options);
}
export type GetProductByIdQueryHookResult = ReturnType<
    typeof useGetProductByIdQuery
>;
export type GetProductByIdLazyQueryHookResult = ReturnType<
    typeof useGetProductByIdLazyQuery
>;
export type GetProductByIdSuspenseQueryHookResult = ReturnType<
    typeof useGetProductByIdSuspenseQuery
>;
export type GetProductByIdQueryResult = Apollo.QueryResult<
    GetProductByIdQuery,
    GetProductByIdQueryVariables
>;
export const GetAllProductsDocument = gql`
    query getAllProducts {
        Products {
            id
            remoteId
            code
            description
            servingUnit
            primaryUnitType
            retailCount
            grossWeightBase
            holePunchTearNotch
            holePunchTearNotchDetails
            gtin14
            upc12
            former
            overlap
            seal
            cutoff
            prices {
                id
                price
                currency
            }
            weightUnit {
                id
                type
                unit
            }
            lengthUnit {
                id
                type
                unit
            }
            areaUnit {
                id
                type
                unit
            }
            prices {
                id
                price
                currency
                priceLevel
                priceQuantity
            }
            items {
                id
                flavor
                guaranteedShelfLife
                shelfLifeDays
                lengthLeftToRight
                widthFrontToBack
                heightTopToBottom
                netWeight
                sellableWeight
                filmWebWidth
                weightUnit {
                    id
                    type
                    unit
                }
                lengthUnit {
                    id
                    type
                    unit
                }
            }
            caseInfo {
                id
                casePack
                masterCaseCube
                weightUnit {
                    id
                    type
                    unit
                }
                lengthUnit {
                    id
                    type
                    unit
                }
                areaUnit {
                    id
                    type
                    unit
                }
                masterCaseLengthLeftToRight
                masterCaseWidthFrontToBack
                masterCaseHeightTopToBottom
                masterCaseNetWt
            }
            palletInfo {
                id
                perTruckload
                perContainer
                ti
                hi
                casesOrUnitsPer
                grossWeightWoPallet
                grossWeight
                heightTopToBottomWoPallet
                netWeight
                heightTopToBottom
                widthFrontToBack
                lengthLeftToRight
                weightUnit {
                    id
                    type
                    unit
                }
                lengthUnit {
                    id
                    type
                    unit
                }
                areaUnit {
                    id
                    type
                    unit
                }
                cube
                cubeCFWoPallet
            }
            displayInfo {
                id
                lengthLeftToRight
                widthFrontToBack
                heightTopToBottom
                netWeight
                weightUnit {
                    id
                    type
                    unit
                }
                lengthUnit {
                    id
                    type
                    unit
                }
            }
            shipperInfo {
                id
                lengthLeftToRight
                widthFrontToBack
                heightTopToBottom
                netWeight
                cube
                weightUnit {
                    id
                    type
                    unit
                }
                lengthUnit {
                    id
                    type
                    unit
                }
                areaUnit {
                    id
                    type
                    unit
                }
            }
            truckloadInfo {
                id
                grossWeighttPer
                unitsPer
                stackLevel
                weightUnit {
                    id
                    type
                    unit
                }
            }
        }
    }
`;

/**
 * __useGetAllProductsQuery__
 *
 * To run a query within a React component, call `useGetAllProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllProductsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllProductsQuery(
    baseOptions?: Apollo.QueryHookOptions<
        GetAllProductsQuery,
        GetAllProductsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetAllProductsQuery, GetAllProductsQueryVariables>(
        GetAllProductsDocument,
        options
    );
}
export function useGetAllProductsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetAllProductsQuery,
        GetAllProductsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        GetAllProductsQuery,
        GetAllProductsQueryVariables
    >(GetAllProductsDocument, options);
}
export function useGetAllProductsSuspenseQuery(
    baseOptions?: Apollo.SuspenseQueryHookOptions<
        GetAllProductsQuery,
        GetAllProductsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        GetAllProductsQuery,
        GetAllProductsQueryVariables
    >(GetAllProductsDocument, options);
}
export type GetAllProductsQueryHookResult = ReturnType<
    typeof useGetAllProductsQuery
>;
export type GetAllProductsLazyQueryHookResult = ReturnType<
    typeof useGetAllProductsLazyQuery
>;
export type GetAllProductsSuspenseQueryHookResult = ReturnType<
    typeof useGetAllProductsSuspenseQuery
>;
export type GetAllProductsQueryResult = Apollo.QueryResult<
    GetAllProductsQuery,
    GetAllProductsQueryVariables
>;
export const GetUserByIdDocument = gql`
    query getUserById($id: String!) {
        User(id: $id) {
            id
            email
            firstName
            lastName
            organization
            phone
            avatarUrl
            jobTitle
            roles
            createdBy {
                id
            }
            deletedAt
            updatedBy {
                id
            }
        }
    }
`;

/**
 * __useGetUserByIdQuery__
 *
 * To run a query within a React component, call `useGetUserByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserByIdQuery(
    baseOptions: Apollo.QueryHookOptions<
        GetUserByIdQuery,
        GetUserByIdQueryVariables
    > &
        (
            | { variables: GetUserByIdQueryVariables; skip?: boolean }
            | { skip: boolean }
        )
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetUserByIdQuery, GetUserByIdQueryVariables>(
        GetUserByIdDocument,
        options
    );
}
export function useGetUserByIdLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetUserByIdQuery,
        GetUserByIdQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GetUserByIdQuery, GetUserByIdQueryVariables>(
        GetUserByIdDocument,
        options
    );
}
export function useGetUserByIdSuspenseQuery(
    baseOptions?: Apollo.SuspenseQueryHookOptions<
        GetUserByIdQuery,
        GetUserByIdQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<GetUserByIdQuery, GetUserByIdQueryVariables>(
        GetUserByIdDocument,
        options
    );
}
export type GetUserByIdQueryHookResult = ReturnType<typeof useGetUserByIdQuery>;
export type GetUserByIdLazyQueryHookResult = ReturnType<
    typeof useGetUserByIdLazyQuery
>;
export type GetUserByIdSuspenseQueryHookResult = ReturnType<
    typeof useGetUserByIdSuspenseQuery
>;
export type GetUserByIdQueryResult = Apollo.QueryResult<
    GetUserByIdQuery,
    GetUserByIdQueryVariables
>;
export const GetAllUsersDocument = gql`
    query getAllUsers {
        Users {
            id
            email
            firstName
            lastName
            organization
            phone
            avatarUrl
            jobTitle
            roles
            createdBy {
                id
            }
            deletedAt
            updatedBy {
                id
            }
        }
    }
`;

/**
 * __useGetAllUsersQuery__
 *
 * To run a query within a React component, call `useGetAllUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllUsersQuery(
    baseOptions?: Apollo.QueryHookOptions<
        GetAllUsersQuery,
        GetAllUsersQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetAllUsersQuery, GetAllUsersQueryVariables>(
        GetAllUsersDocument,
        options
    );
}
export function useGetAllUsersLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetAllUsersQuery,
        GetAllUsersQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GetAllUsersQuery, GetAllUsersQueryVariables>(
        GetAllUsersDocument,
        options
    );
}
export function useGetAllUsersSuspenseQuery(
    baseOptions?: Apollo.SuspenseQueryHookOptions<
        GetAllUsersQuery,
        GetAllUsersQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<GetAllUsersQuery, GetAllUsersQueryVariables>(
        GetAllUsersDocument,
        options
    );
}
export type GetAllUsersQueryHookResult = ReturnType<typeof useGetAllUsersQuery>;
export type GetAllUsersLazyQueryHookResult = ReturnType<
    typeof useGetAllUsersLazyQuery
>;
export type GetAllUsersSuspenseQueryHookResult = ReturnType<
    typeof useGetAllUsersSuspenseQuery
>;
export type GetAllUsersQueryResult = Apollo.QueryResult<
    GetAllUsersQuery,
    GetAllUsersQueryVariables
>;
