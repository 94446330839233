import {
    ApolloClient,
    ApolloQueryResult,
    DocumentNode,
    OperationVariables,
    TypedDocumentNode,
} from '@apollo/client';
import {
    useQuery,
    UseQueryOptions,
    QueryKey,
    useMutation,
} from '@tanstack/react-query';
// import { FetchResult } from '@apollo/client';

//this is to avoid a bug in the type declaration of apolloclients mutation result type
//https://github.com/apollographql/apollo-client/issues/9292
// type ConnectFetchResult<T> = FetchResult<T> & {
//     errors: [Error];
// };

export type FetchPolicy =
    | 'cache-first'
    | 'network-only'
    | 'cache-only'
    | 'no-cache'
    | 'standby';
class BaseSDKHandler<
    CMutation,
    CMutationVariables extends OperationVariables,
    RQuery,
    RQueryVariables extends OperationVariables,
    UMutation,
    UMutationVariables extends OperationVariables,
    DMutation,
    DMutationVariables extends OperationVariables,
    AQuery,
    AQueryVariables extends OperationVariables
> {
    constructor(
        public client: ApolloClient<object>,
        private entity: string,
        private cDoc:
            | DocumentNode
            | TypedDocumentNode<CMutation, CMutationVariables>,
        private rDoc: DocumentNode | TypedDocumentNode<RQuery, RQueryVariables>,
        private uDoc:
            | DocumentNode
            | TypedDocumentNode<UMutation, UMutationVariables>,
        private dDoc:
            | DocumentNode
            | TypedDocumentNode<DMutation, DMutationVariables>,
        private aDoc: DocumentNode | TypedDocumentNode<AQuery, AQueryVariables>
    ) {}

    // ======> queries
    useGetOne = <TData = RQuery, TError = Error>(
        variables: RQueryVariables,
        options?: Omit<
            UseQueryOptions<RQuery, TError, TData, QueryKey>,
            'queryKey'
        >
    ) => {
        const queryInfo = useQuery({
            queryKey: [this.entity, 'get', variables['id']],
            queryFn: async () => {
                const result = await this.client.query<RQuery, RQueryVariables>(
                    {
                        query: this.rDoc,
                        variables,
                    }
                );
                if (result.errors) {
                    throw Error(result.errors.map((e) => e.message).join('\n'));
                }
                if (result.error) {
                    throw Error(result.error.message);
                }
                return result.data;
            },
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
            ...options,
        });
        return { ...queryInfo };
    };

    useGetAll = <TData = AQuery, TError = Error>(
        variables?: AQueryVariables,
        options?: Omit<
            UseQueryOptions<AQuery, TError, TData, QueryKey>,
            'queryKey'
        > & {
            fetchPolicy?: FetchPolicy;
        }
    ) => {
        const queryInfo = useQuery({
            queryKey: [this.entity, 'get'],
            queryFn: async () => {
                const result: ApolloQueryResult<AQuery> =
                    await this.client.query<AQuery, AQueryVariables>({
                        query: this.aDoc,
                        variables,
                        fetchPolicy: options?.fetchPolicy || 'cache-first',
                    });
                if (result.errors) {
                    throw Error(result.errors.map((e) => e.message).join('\n'));
                }
                if (result.error) {
                    throw Error(result.error.message);
                }
                return result.data;
            },
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
            ...options,
        });
        return { ...queryInfo };
    };

    // ======> mutations
    useCreate = () =>
        useMutation({
            mutationFn: (variables: CMutationVariables) => {
                return this.client.mutate<CMutation, CMutationVariables>({
                    mutation: this.cDoc,
                    variables,
                });
            },
        });

    useUpdate = () =>
        useMutation({
            mutationFn: (variables: UMutationVariables) => {
                return this.client.mutate<UMutation, UMutationVariables>({
                    mutation: this.uDoc,
                    variables,
                });
            },
        });

    useDelete = () =>
        useMutation({
            mutationFn: (variables: DMutationVariables) => {
                return this.client.mutate<DMutation, DMutationVariables>({
                    mutation: this.dDoc,
                    variables,
                });
            },
        });
}

export default BaseSDKHandler;
