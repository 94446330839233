import { ApolloClient, ApolloQueryResult, FetchPolicy } from '@apollo/client';
import { QueryKey, UseQueryOptions, useQuery } from '@tanstack/react-query';
import {
    CreateNewDocumentDocument,
    CreateNewDocumentMutation,
    CreateNewDocumentMutationVariables,
    DeleteDocumentDocument,
    DeleteDocumentMutation,
    DeleteDocumentMutationVariables,
    GetAllDocumentsDocument,
    GetAllDocumentsQuery,
    GetAllDocumentsQueryVariables,
    GetDocumentByIdDocument,
    GetDocumentByIdQuery,
    GetDocumentByIdQueryVariables,
    GetMapValuesDocument,
    GetMapValuesQuery,
    GetMapValuesQueryVariables,
    UpdateExistingDocumentDocument,
    UpdateExistingDocumentMutation,
    UpdateExistingDocumentMutationVariables,
} from '../graphql/graphql-schema';
import BaseSDKHandler from './base-handler';

class DocumentsHandler extends BaseSDKHandler<
    CreateNewDocumentMutation,
    CreateNewDocumentMutationVariables,
    GetDocumentByIdQuery,
    GetDocumentByIdQueryVariables,
    UpdateExistingDocumentMutation,
    UpdateExistingDocumentMutationVariables,
    DeleteDocumentMutation,
    DeleteDocumentMutationVariables,
    GetAllDocumentsQuery,
    GetAllDocumentsQueryVariables
> {
    constructor(client: ApolloClient<object>) {
        super(
            client,
            'documents',
            CreateNewDocumentDocument,
            GetDocumentByIdDocument,
            UpdateExistingDocumentDocument,
            DeleteDocumentDocument,
            GetAllDocumentsDocument
        );
    }

    // ======> helpers
    useDocuments = () => {
        return this.useGetAll(undefined, {
            fetchPolicy: 'network-only',
            refetchOnReconnect: true,
            refetchInterval: 5000, // refetch documents every 5 seconds, useful for multiple people viewing updates
            select: (data) => data?.documents,
        });
    };

    useGetMapValues = (
        variables?: GetMapValuesQueryVariables,
        options?: Omit<
            UseQueryOptions<
                GetMapValuesQuery,
                Error,
                GetMapValuesQuery,
                QueryKey
            >,
            'queryKey'
        > & {
            fetchPolicy?: FetchPolicy;
        }
    ) => {
        const queryInfo = useQuery({
            queryKey: ['productMapValues', 'get'],
            queryFn: async () => {
                const result: ApolloQueryResult<GetMapValuesQuery> =
                    await this.client.query<
                        GetMapValuesQuery,
                        GetMapValuesQueryVariables
                    >({
                        query: GetMapValuesDocument,
                        variables,
                        fetchPolicy: options?.fetchPolicy || 'cache-first',
                    });
                if (result.errors) {
                    throw Error(result.errors.map((e) => e.message).join('\n'));
                }
                if (result.error) {
                    throw Error(result.error.message);
                }
                return result.data;
            },
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
            ...options,
        });
        return { ...queryInfo };
    };
}

export function createDocumentsHandler(client: ApolloClient<object>) {
    return new DocumentsHandler(client);
}
