import { ApolloClient } from '@apollo/client';
import BaseSDKHandler from './base-handler';
import {
    CreateNewCustomerDocument,
    CreateNewCustomerMutation,
    CreateNewCustomerMutationVariables,
    DeleteCustomerDocument,
    DeleteCustomerMutation,
    DeleteCustomerMutationVariables,
    GetAllCustomersDocument,
    GetAllCustomersQuery,
    GetAllCustomersQueryVariables,
    GetCustomerByIdQuery,
    GetCustomerByIdQueryVariables,
    GetProductByIdDocument,
    UpdateExistingCustomerDocument,
    UpdateExistingCustomerMutation,
    UpdateExistingCustomerMutationVariables,
} from '../graphql/graphql-schema';

class CustomersHandler extends BaseSDKHandler<
    CreateNewCustomerMutation,
    CreateNewCustomerMutationVariables,
    GetCustomerByIdQuery,
    GetCustomerByIdQueryVariables,
    UpdateExistingCustomerMutation,
    UpdateExistingCustomerMutationVariables,
    DeleteCustomerMutation,
    DeleteCustomerMutationVariables,
    GetAllCustomersQuery,
    GetAllCustomersQueryVariables
> {
    constructor(client: ApolloClient<object>) {
        super(
            client,
            'customers',
            CreateNewCustomerDocument,
            GetProductByIdDocument,
            UpdateExistingCustomerDocument,
            DeleteCustomerDocument,
            GetAllCustomersDocument
        );
    }

    // ======> helpers
    useCustomers = () => {
        return this.useGetAll(undefined, {
            select: (data) => data?.customers,
        });
    };
}

export function createCustomersHandler(client: ApolloClient<object>) {
    return new CustomersHandler(client);
}
