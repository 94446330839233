import { ApolloClient } from '@apollo/client';
import {
    GetProductByIdQueryVariables,
    GetAllProductsDocument,
    GetAllProductsQueryVariables,
    GetAllProductsQuery,
    GetProductByIdQuery,
    GetProductByIdDocument,
    CreateNewProductDocument,
    CreateNewProductMutationVariables,
    CreateNewProductMutation,
    UpdateExistingProductMutation,
    UpdateExistingProductMutationVariables,
    UpdateExistingProductDocument,
    DeleteProductMutation,
    DeleteProductMutationVariables,
    DeleteProductDocument,
} from '../graphql/graphql-schema';
import BaseSDKHandler from './base-handler';

class ProductsHandler extends BaseSDKHandler<
    CreateNewProductMutation,
    CreateNewProductMutationVariables,
    GetProductByIdQuery,
    GetProductByIdQueryVariables,
    UpdateExistingProductMutation,
    UpdateExistingProductMutationVariables,
    DeleteProductMutation,
    DeleteProductMutationVariables,
    GetAllProductsQuery,
    GetAllProductsQueryVariables
> {
    constructor(client: ApolloClient<object>) {
        super(
            client,
            'products',
            CreateNewProductDocument,
            GetProductByIdDocument,
            UpdateExistingProductDocument,
            DeleteProductDocument,
            GetAllProductsDocument
        );
    }

    // ======> helpers
    useProducts = () => {
        return this.useGetAll(undefined, {
            select: (data) => data?.Products,
        });
    };
}

export function createProductsHandler(client: ApolloClient<object>) {
    return new ProductsHandler(client);
}
