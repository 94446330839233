import { ApolloClient } from '@apollo/client';
import {
    CreateNewUserDocument,
    CreateNewUserMutation,
    CreateNewUserMutationVariables,
    GetUserByIdQuery,
    GetUserByIdQueryVariables,
    GetUserByIdDocument,
    UpdateExistingUserMutation,
    UpdateExistingUserMutationVariables,
    UpdateExistingUserDocument,
    DeleteUserMutation,
    DeleteUserMutationVariables,
    DeleteUserDocument,
    GetAllUsersQuery,
    GetAllUsersQueryVariables,
    GetAllUsersDocument,
    GetCurrentUserDocument,
    GetCurrentUserQuery,
    GetCurrentUserQueryVariables,
} from '../graphql/graphql-schema';
import BaseSDKHandler from './base-handler';
import { useQuery, UseQueryOptions, QueryKey } from '@tanstack/react-query';

class UsersHandler extends BaseSDKHandler<
    CreateNewUserMutation,
    CreateNewUserMutationVariables,
    GetUserByIdQuery,
    GetUserByIdQueryVariables,
    UpdateExistingUserMutation,
    UpdateExistingUserMutationVariables,
    DeleteUserMutation,
    DeleteUserMutationVariables,
    GetAllUsersQuery,
    GetAllUsersQueryVariables
> {
    constructor(client: ApolloClient<object>) {
        super(
            client,
            'users',
            CreateNewUserDocument,
            GetUserByIdDocument,
            UpdateExistingUserDocument,
            DeleteUserDocument,
            GetAllUsersDocument
        );
    }

    // ======> queries
    useGetCurrentUser = <TData = GetCurrentUserQueryVariables, TError = Error>(
        variables?: GetCurrentUserQueryVariables,
        options?: Omit<
            UseQueryOptions<GetCurrentUserQuery, TError, TData, QueryKey>,
            'queryKey'
        >
    ) => {
        const queryInfo = useQuery({
            queryKey: ['users', 'getCurrentUser'],
            queryFn: async () => {
                const result = await this.client.query<
                    GetCurrentUserQuery,
                    GetCurrentUserQueryVariables
                >({
                    query: GetCurrentUserDocument,
                    variables,
                });
                if (result.errors) {
                    throw Error(result.errors.map((e) => e.message).join('\n'));
                }
                if (result.error) {
                    throw Error(result.error.message);
                }
                return result.data;
            },
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
            ...options,
        });
        return { ...queryInfo };
    };

    // ======> mutations

    // ======> helpers
    useUser = () => {
        return this.useGetCurrentUser(undefined, {
            select: (data) => data?.me,
        });
    };

    useUserId = () => {
        return this.useGetCurrentUser(undefined, {
            select: (data) => data?.me?.id,
        });
    };

    useIsLoggedIn = () => {
        return this.useGetCurrentUser(undefined, {
            select: (data) => Boolean(data?.me?.id),
        });
    };

    useUserOrg = () => {
        return this.useGetCurrentUser(undefined, {
            select: (data) => data?.me?.organization,
        });
    };
}

export function createUsersHandler(client: ApolloClient<object>) {
    return new UsersHandler(client);
}
