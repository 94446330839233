// add routes here so they update everywhere when changed.
export const ROUTES = {
    HOME: '/dashboard',
    PROFILE: '/profile',
    DASHBOARD: '/dashboard',
    TEMPLATES: {
        ROOT: '/templates',
        CUSTOMER: '/templates/customer',
    },
    MASTER_DATA: {
        ROOT: '/master-data',
        PRODUCTS: '/master-data?tab=products',
        CUSTOMERS: '/master-data?tab=customers',
        DATA_DICTIONARY: '/master-data?tab=data-dictionary',
    },
    ADMIN: {
        ROOT: '/admin',
        NEW_ORGANIZATION: '/admin/organizations/new',
        ORGANIZATION: (id: string) => `/admin/organizations/${id}`,
    },
    AUTH: {
        // used in apiClient (/libs/api.js) upon 401 errors from our API
        LOGIN: '/auth/login',
        LOGOUT: '/auth/logout',
        FORGOT_PASSWORD: '/auth/forgot-password',
        RESET_PASSWORD: '/auth/reset-password',
        ERROR: '/auth/error',
        VERIFY: '/auth/verify',
    },
    MISC: {
        PRIVACY_POLICY: '/privacy-policy',
        TERMS_AND_CONDITIONS: '/tos',
    },
};
